export class ErrorUtils {
  static parseError(error: { message: string }) {
    const message = error.message;
    const pos = message.indexOf(" ");
    const exchangeId = message.substring(0, pos);
    const json = message.substring(pos + 1);
    try {
      const errorObj = JSON.parse(json);
      errorObj.exchangeId = exchangeId;
      return errorObj;
    } catch (e) {
      return;
    }
  }

  static parseErrorMessage(reason: { message: string }) {
    const errorObj = ErrorUtils.parseError(reason);
    if (errorObj) {
      const errorClass = reason.constructor.name;
      if (errorObj.exchangeId === "binance") {
        return errorClass + " - " + errorObj.msg;
      } else if (errorObj.exchangeId === "gateio") {
        return errorClass + " - " + errorObj.message;
      }
    }
    return reason.message;
  }
}
