// noinspection JSPotentiallyInvalidConstructorUsage

import { okx, Currency, Dictionary, Account, Market, MarketSide, MaxBorrowable, MarginMode } from "ccxt";

const getValue = (result: PromiseSettledResult<any>) => {
  return result && result.status === "fulfilled" ? result.value : undefined;
};

export class OkxEx {
  static fixPrototype() {
    okx.prototype.safeNetwork = function (networkId: string) {
      const networksById: Dictionary<string> = {
        "Avalanche C": "AVAXC",
        "Avalanche X": "",
        "Bitcoin Diamond": "",
        "Bitcoin SV": "",
        "Digital Cash": "",
        "Ethereum Classic": "ETC",
        "F4SBTC-": "",
        "New Economy Movement": "",
        "OLDWBTC-": "",
        "Stellar Lumens": "",
        AAC: "",
        AELF: "ELF",
        ALGO: "",
        ARK: "",
        Acala: "",
        Achain: "",
        Aeternity: "",
        Algorand: "ALGO",
        Arbitrum: "ARBITRUM",
        Arweave: "",
        Asch: "",
        Astar: "",
        BHP: "",
        BSC: "BSC",
        BTC: "BTC",
        Bifrost: "",
        BitcoinCash: "BCH",
        BitcoinGold: "",
        BitcoinX: "",
        Bytom: "",
        CELO: "CELO",
        Cardano: "ADA",
        Casper: "",
        Chia: "",
        ChiliZ: "",
        Chiliz: "",
        Conflux: "",
        Cortex: "",
        Cosmos: "ATOM",
        Crypto: "",
        CyberMiles: "",
        DNA: "",
        Decred: "",
        Dfinity: "ICP",
        Digibyte: "",
        Dogecoin: "DOGE",
        EOS: "",
        ERC20: "ETH",
        Elrond: "",
        Eminer: "",
        EthereumPoW: "ETHW",
        FLOW: "FLOW",
        Fantom: "FTM",
        Filecoin: "FIL",
        Fusion: "",
        GoChain: "",
        Harmony: "",
        Hedera: "",
        Horizen: "ZEN",
        HyperCash: "",
        ICON: "",
        INTCHAIN: "",
        IOST: "",
        ITC: "",
        KAR: "",
        Kadena: "",
        Khala: "",
        Klaytn: "",
        Kusama: "KSM",
        l: "",
        Lightning: "",
        Liquid: "",
        Lisk: "",
        Litecoin: "LTC",
        MIOTA: "",
        Metis: "",
        Mina: "MINA",
        Monero: "",
        Moonbeam: "",
        Moonriver: "",
        N3: "",
        NEAR: "",
        NEO: "",
        NULS: "",
        Nano: "",
        Nebulas: "",
        OKC: "",
        OKExChain_KIP10: "",
        OLDMIOTA: "",
        OMNI: "",
        Ontology: "ONT",
        Optimism: "OP",
        PlatON: "",
        Polkadot: "DOT",
        Polygon: "MATIC",
        Quantum: "",
        Ravencoin: "",
        Ripple: "XRP",
        Ronin: "",
        SDN: "",
        Siacoin: "",
        Solana: "SOL",
        TON: "",
        TRC20: "TRX",
        TRON: "TRX",
        Terra: "LUNA",
        Tezos: "XTZ",
        Theta: "",
        TrueChain: "",
        VSYSTEMS: "",
        WAVES: "",
        WGRT: "",
        Walton: "",
        Wax: "",
        XEC: "",
        YOU: "",
        YOYOW: "",
        ZKSYNC: "",
        Zcash: "ZEC",
        Zilliqa: "",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    const fetchCurrenciesOld = okx.prototype.fetchCurrencies;
    okx.prototype.fetchCurrencies = async function (params = {}) {
      const result: Dictionary<Currency> = await fetchCurrenciesOld.call(this, params);
      for (const currency of Object.values(result)) {
        //Bug fix for LUNC token, because it gives back LUNA (originally Terra) network for both LUNA and LUNC
        if (currency.code === "LUNC") {
          //@ts-ignore
          if (currency.networks["LUNA"] !== undefined) {
            //@ts-ignore
            currency.networks["LUNC"] = currency.networks["LUNA"]; //@ts-ignore
            currency.networks["LUNC"].network = "LUNC"; //@ts-ignore
            delete currency.networks["LUNA"];
          }
        }

        if (currency.fees === undefined) {
          currency.fees = {};
          for (const network of Object.values(currency.networks)) {
            currency.fees[network.network] = network.fee;
          }
        }
      }
      return result;
    };

    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    okx.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString(ticker, "instId");
      const symbol = this.safeSymbol(marketId, market);
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "bidPx"),
        bidVolume: this.safeNumber(ticker, "bidSz"),
        ask: this.safeNumber(ticker, "askPx"),
        askVolume: this.safeNumber(ticker, "askSz"),
        last: this.safeNumber(ticker, "last"),
      };
    };

    const fetchMarketsOld = okx.prototype.fetchMarkets;
    okx.prototype.fetchMarkets = async function (params = {}) {
      // @ts-ignore
      const markets: Market[] = await fetchMarketsOld.call(this, params);
      for (const market of markets) {
        //NOTE: All margined market has both cross and isolated margin with the same leverage and maxBorrow.
        //      Basically this single currency cross mode exactly looks like isolated margin mode.
        market.crossMargin = market.info.lever !== undefined;
        market.crossMaxLeverage = market.info.lever;

        // NOTE: Disabling isolated margin, I could use it only for selling.
        //      (For buying only "base" asset can be used as collateral, but I have only "quote" asset as collateral)
        //       MaxBorrow and leverage are the same, so does not make sense to use it.
        market.isolatedMargin = false; //market.info.lever !== undefined;
        market.isolatedMaxLeverage = undefined; //market.info.lever;
      }
      return markets;
    };

    okx.prototype.transferEx = async function (
      code: string,
      amount: number,
      fromAccount: Account,
      toAccount: Account,
      fromSymbol?: string,
      toSymbol?: string,
      params = {}
    ) {
      if (fromSymbol !== undefined || toSymbol !== undefined) {
        throw new Error("Okx: fromSymbol / toSymbol is not supported in transferEx: " + fromSymbol + ", " + toSymbol);
      }
      return await okx.prototype.transfer.call(this, code, amount, fromAccount, toAccount, params);
    };

    okx.prototype.fetchMaxBorrowableHelper = async function (
      market: Market,
      marketSide: MarketSide,
      marginMode: MarginMode
    ) {
      const maxLeverage =
        marginMode === "cross"
          ? market.crossMaxLeverage
          : marginMode === "isolated"
          ? market.isolatedMaxLeverage
          : undefined;
      if (maxLeverage === undefined) {
        return;
      }

      const promises: Promise<any>[] = [
        this.privateGetAccountInterestLimits({
          ccy: marketSide === "base" ? market.baseId : market.quoteId,
        }),
        this.privateGetAccountMaxLoan({
          instId: market.id,
          mgnMode: marginMode,
          mgnCcy: market.quoteId,
        }),
      ];
      const [limitResult, maxResult] = await Promise.allSettled(promises);

      const limitData = getValue(limitResult);
      const maxData = getValue(maxResult);

      const limitObj = limitData?.data?.[0]?.records?.[0];
      const limit = this.safeNumber(limitObj, "surplusLmt");

      const maxObj = (maxData?.data ?? []).find((obj: any) => obj?.side === (marketSide === "base" ? "sell" : "buy"));
      const grossAmount = this.safeNumber(maxObj, "maxLoan");
      //NOTE: We get back the collateral as part of the loan, so we need to deduct that
      const amount = grossAmount * ((maxLeverage - 1) / maxLeverage);

      const maxBorrowable: MaxBorrowable = { amount: amount, limit: limit };
      return maxBorrowable;
    };

    okx.prototype.fetchMaxBorrowableCross = async function (market: Market, marketSide: MarketSide) {
      return this.fetchMaxBorrowableHelper(market, marketSide, "cross");
    };

    okx.prototype.fetchMaxBorrowableIsolated = async function (market: Market, marketSide: MarketSide) {
      return this.fetchMaxBorrowableHelper(market, marketSide, "isolated");
    };

    okx.prototype.getCrossMarginRisk = function (balance: any, quote?: string) {
      const data = balance?.info?.data[0]?.details?.find((asset: any) => asset.ccy === quote);
      console.log("getCrossRisk", data, balance?.info, balance);
      return data?.mgnRatio;
    };

    okx.prototype.getIsolatedMarginRisk = function (balance: any, marketId: string, quote?: string) {
      //const isolatedBalance = balance?.info?.assets?.find((asset: any) => asset.symbol === marketId);
      console.log("getIsolatedRisk", balance?.info, balance, marketId, quote);
      //return isolatedBalance?.marginLevel;
      return undefined;
    };

    //NOTE: This is not tested and not used
    // const fetchBalanceOld = okx.prototype.fetchBalance;
    // okx.prototype.fetchBalance = async function (params = {}) {
    //   const [marketType] = this.handleMarketTypeAndParams("fetchBalance", undefined, params);
    //   if (marketType === "funding") {
    //     return await fetchBalanceOld.call(this, params);
    //   }
    //
    //   const promises: [Promise<Balances>, Promise<any>] = [
    //     fetchBalanceOld.call(this, params),
    //     this.privateGetAccountInterestLimits(),
    //   ];
    //   const [balanceResult, interestLimitsResult] = await Promise.all(promises);
    //
    //   const records = interestLimitsResult.data[0].records;
    //   console.log(records);
    //   for (const record of records) {
    //     const currencyCode = this.safeString(record, "ccy");
    //     const borrowed = this.safeNumber(record, "usedLmt");
    //     if (balanceResult[currencyCode] !== undefined) {
    //       balanceResult[currencyCode]["borrowed"] = borrowed;
    //       //@ts-ignore
    //       balanceResult["borrowed"][currencyCode] = borrowed;
    //     }
    //   }
    //   console.log("TEST", balanceResult);
    //
    //   return balanceResult;
    // };
  }
}
