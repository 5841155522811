// noinspection JSPotentiallyInvalidConstructorUsage

import { bitmart, Currency, TransactionFeeData, IsolatedMarginData, Dictionary } from "ccxt";
import { Market } from "ccxt";

export class BitmartEx {
  static fixPrototype() {
    const oldDescribe = bitmart.prototype.describe;
    bitmart.prototype.describe = function () {
      const describe = oldDescribe.call(this);
      describe.has.fetchAllIsolatedMarginData = true;
      return describe;
    };

    bitmart.prototype.safeNetwork = function (networkId: string) {
      const networksById: Dictionary<string> = {
        "AVAX-C": "AVAXC",
        "BEP20 (BSC)": "BSC",
        "BEP20(BSC)": "BSC",
        "CFX eSpace": "",
        "ERC-20": "ETH",
        ABBC: "",
        ACA: "",
        ADA: "",
        AKT: "",
        ALGO: "",
        ALGORAND: "ALGO",
        Algorand: "ALGO",
        APL: "",
        AR: "",
        ARC20: "",
        ATOM: "",
        AVAT: "",
        AVAX: "",
        BCH: "",
        BEP20: "BSC",
        BEP2: "BNB",
        BERS: "",
        BHD: "",
        BondDex: "",
        BRC: "",
        BRISE: "",
        BSC_BNB: "BSC",
        BSV: "",
        BTC: "",
        BTCV: "",
        BTM: "",
        Cardano: "ADA",
        Casper: "",
        CC: "",
        CCX: "",
        CELO: "",
        CFX: "",
        CHEQ: "",
        CLC: "",
        CMP: "",
        Computer: "",
        CPH: "",
        CRO: "",
        CRO_Chain: "CRO",
        CS: "",
        CSOV: "",
        DAPS: "",
        DASH: "",
        DGB: "",
        DINGO: "",
        DOGE: "",
        DOT: "",
        EGLD: "",
        EOS: "",
        EPIC: "",
        ERC20: "ETH",
        ERG: "",
        ETC: "",
        ETE: "",
        EVER: "",
        EWT: "",
        Fantom: "FTM",
        FIC: "",
        FIL: "",
        FILM: "",
        FIO: "",
        FIRO: "",
        FUSE: "",
        GLMR: "",
        GMD: "",
        GRIN: "",
        HBAR: "",
        HDD: "",
        HECO: "",
        HRC20: "",
        HYDRA: "",
        Idena: "",
        IGT: "",
        IOTX: "",
        KALAM: "",
        KAR: "",
        KardiaChain: "",
        KAVA: "",
        KLAY: "",
        Klaytn: "KLAY",
        klaytn: "KLAY",
        KRC20: "",
        KSM: "",
        LAT: "",
        LBC: "",
        LED: "",
        LHD: "",
        LTC: "",
        LTNM: "",
        LUNC: "",
        MATIC: "",
        MBX: "",
        MERGE: "",
        mHLX: "",
        MINA: "",
        MOVR: "",
        mrx: "",
        MXW: "",
        NAS: "",
        NDAU: "",
        NEAR: "",
        NEO: "",
        NFC: "",
        NULS: "",
        NVT: "",
        OK: "",
        OMNI: "",
        ONE: "",
        OPTIMISM: "",
        PAC: "",
        PHAE: "",
        PKT: "",
        PLCU: "",
        PLEX: "",
        PLUGCN: "",
        POLYGON: "MATIC",
        Polygon: "MATIC",
        polygon: "MATIC",
        QTUM: "",
        RDD: "",
        REEF: "",
        REV: "",
        RUN: "",
        SCRT: "",
        SMH: "",
        SOL: "",
        Solana: "SOL",
        Solar: "",
        SPL: "",
        Stellar: "XLM",
        SYS: "",
        Terra: "LUNA",
        THETA: "",
        TLOS: "",
        TRC10: "TRX",
        TRC20: "TRX",
        trc20: "TRX",
        TRX: "",
        TSL: "",
        UGC: "",
        UPG: "",
        UTG: "",
        VBK: "",
        Vechain: "VET",
        VEIL: "",
        VET: "",
        VITE: "",
        VLX: "",
        VSOL: "",
        VTS: "",
        WAVES: "",
        WICC: "",
        XAG: "",
        XBN: "",
        XCH: "",
        XDC: "",
        XDEN: "",
        XEM: "",
        XHD: "",
        XIN: "",
        XLM: "",
        XRP: "",
        XTZ: "",
        XYM: "",
        ZEBI: "",
        ZEC: "",
        ZENITH: "",
        ZIL: "",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    bitmart.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString2(ticker, "symbol", "contract_symbol");
      market = this.safeMarket(marketId, market);
      const symbol = market["symbol"];
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "best_bid"),
        bidVolume: this.safeNumber(ticker, "best_bid_size"),
        ask: this.safeNumber(ticker, "best_ask"),
        askVolume: this.safeNumber(ticker, "best_ask_size"),
        last: this.parseNumber(this.safeString2(ticker, "close_24h", "last_price")),
      };
    };

    bitmart.prototype.fetchCurrencies = async function (params = {}) {
      const groupByCurrency = (currencies) => {
        const result = {};
        for (const currencyInfo of currencies) {
          const p = currencyInfo["currency"].split("-")[0];
          currencyInfo["name"] = currencyInfo["name"].replace("�", " ").trim();
          currencyInfo["network"] = currencyInfo["network"].replace("�", " ").trim();
          result[p] = result[p] || [];
          result[p].push(currencyInfo);
        }
        return result;
      };

      const response = await this.privateGetAccountV1Currencies(params);
      const data = this.safeValue(response, "data", {});
      const currencies = this.safeValue(data, "currencies", []);
      const result: Dictionary<any> = {};
      const dataByCurrencyId = groupByCurrency(currencies);
      const currencyIds = Object.keys(dataByCurrencyId);
      for (let i = 0; i < currencyIds.length; i++) {
        const currencyId = currencyIds[i];
        const currency = this.safeCurrency(currencyId);
        const code = currency["code"];
        const chains = dataByCurrencyId[currencyId];
        const networks = {};
        let currencyActive = false;
        let depositEnabled = undefined;
        let withdrawEnabled = undefined;
        for (let j = 0; j < chains.length; j++) {
          const chain = chains[j];
          const canDeposit = this.safeValue(chain, "deposit_enabled");
          const canWithdraw = this.safeValue(chain, "withdraw_enabled");
          // noinspection RedundantConditionalExpressionJS
          const active = canDeposit && canWithdraw ? true : false;
          currencyActive = currencyActive === undefined ? active : currencyActive;
          const networkId = this.safeString(chain, "network");
          if (canDeposit && !depositEnabled) {
            depositEnabled = true;
          } else if (!canDeposit) {
            depositEnabled = false;
          }
          if (canWithdraw && !withdrawEnabled) {
            withdrawEnabled = true;
          } else if (!canWithdraw) {
            withdrawEnabled = false;
          }
          const network = this.safeNetwork(networkId);
          networks[network] = {
            id: networkId,
            network: network,
            active: active,
            deposit: canDeposit,
            withdraw: canWithdraw,
            fee: undefined,
            precision: undefined,
            limits: {
              amount: { min: undefined, max: undefined },
              withdraw: { min: undefined, max: undefined },
            },
            info: chain,
          };
        }
        const firstChain = this.safeValue(chains, 0);
        result[code] = {
          id: currencyId,
          code: code,
          name: this.safeString(firstChain, "name"),
          info: undefined,
          active: currencyActive,
          deposit: depositEnabled,
          withdraw: withdrawEnabled,
          fee: undefined,
          precision: undefined,
          limits: {
            amount: { min: undefined, max: undefined },
            withdraw: { min: undefined, max: undefined },
          },
          networks: networks,
        };
      }
      return result;
    };

    const fetchSpotMarketsOld = bitmart.prototype.fetchSpotMarkets;
    bitmart.prototype.fetchSpotMarkets = async function (params = {}) {
      const promise1 = fetchSpotMarketsOld.call(this, params);
      const promise2 = this.privateGetSpotV1MarginIsolatedPairs();
      return Promise.all([promise1, promise2]).then((value: [Market[], any[]]) => {
        const markets = value[0];
        const isolatedMarginPairsResponse = value[1];
        const data = this.safeValue(isolatedMarginPairsResponse, "data", {});
        const pairs = this.safeValue(data, "symbols", []);
        markets.map((market) => {
          market.crossMargin = false;

          market.isolatedMargin = pairs.some((pair: any) => pair.symbol === market.info.symbol);
          //?.symbol_enabled === true;

          market.margin = market.crossMargin || market.isolatedMargin;
          return market;
        });
        return markets;
      });
    };

    bitmart.prototype.fetchTransactionFee = async function (this: bitmart, code: string) {
      const currency: Currency = this.currencies[code];

      const info: Dictionary<any> = {};
      const withdraw: Dictionary<Dictionary<number>> = {};
      info[code] = {};
      withdraw[code] = {};
      for (const network of Object.values(currency.networks)) {
        if (network.withdraw === false) {
          continue;
        }
        try {
          const response = await this.privateAccountGetWithdrawCharge({ currency: network.info.currency });
          const fee = this.safeNumber(response.data, "withdraw_fee");
          withdraw[code][network.network] = fee;
          info[code][network.network] = response.data;
        } catch (e: any) {
          //Withdrawal is suspended
          if (e.code === 60004) {
            network.withdraw = false;
            continue;
          }
          throw e;
        }
      }
      const fees: TransactionFeeData = {
        info: info,
        withdraw: withdraw,
        deposit: undefined,
        withdrawPercent: undefined,
      };
      return fees;
    };

    bitmart.prototype.extendCurrency = async function (
      this: bitmart,
      currencyCode: string,
      reload = false,
      callback?: () => void
    ) {
      const currency: Currency = this.currencies[currencyCode];
      if (currency.extended === true && reload === false) {
        return;
      }

      return this.requestPromise("extendCurrency_" + currencyCode, reload, async () => {
        //console.log("Requesting currency from " + this.id, currencyCode);
        const response = await this.fetchTransactionFee(currencyCode);

        currency.fees = response.withdraw[currencyCode];
        currency.extended = true;

        if (callback) {
          callback();
        }
      });
    };

    bitmart.prototype.fetchAllIsolatedMarginData = async function () {
      const response: Array<any> = await this.privateGetSpotV1MarginIsolatedPairs();
      const data = this.safeValue(response, "data", {});
      const items = this.safeValue(data, "symbols", []);

      const marginDataList: Array<IsolatedMarginData> = [];
      for (const item of items) {
        const base = this.safeValue(item, "base");
        const quote = this.safeValue(item, "quote");
        const currencyDataList = [base, quote];
        const marketId = this.safeString(item, "symbol");
        const market = this.safeMarket(marketId);
        const maxLeverage = this.safeNumber(item, "max_leverage");
        for (const currencyData of currencyDataList) {
          const currencyId = this.safeString(currencyData, "currency");
          const marginData: IsolatedMarginData = {
            currencyCode: this.safeCurrencyCode(currencyId),
            symbol: market.symbol,
            maxLeverage,
            borrowLimit: this.safeNumber(currencyData, "borrowable_amount"),
            dailyInterest: this.safeNumber(currencyData, "daily_interest"),
          };
          marginDataList.push(marginData);
        }
      }
      return marginDataList;
    };
  }
}
