import firebase from "@/plugins/firebase";
import BaseCcxtPrivate from "@/Ccxt/CcxtPrivate";

export default // @ts-ignore
class CcxtPrivate extends BaseCcxtPrivate {
  constructor(private exchange: string, private enableRateLimit = false) {
    super(exchange, enableRateLimit);

    this.ccxt.proxy = "/cors-proxy/";

    const fetchImplementationOld = this.ccxt.fetchImplementation;
    this.ccxt.fetchImplementation = function (url: string, options: any) {
      return firebase.getIdToken().then((idToken: string | null) => {
        if (idToken !== null) {
          options.headers["x-auth-token"] = idToken;
        }
        return fetchImplementationOld.call(this, url, options);
      });
    };

    //this.applyProxyToWsUrls();
    //this.fixWsClient();
  }

  private fixWsClient() {
    const oldClientFnc = this.ccxt.client;
    this.ccxt.client = (url: string) => {
      const client = oldClientFnc.call(this.ccxt, url);

      this.fixWsSend(client);

      return client;
    };
  }

  private fixWsSend(client: any) {
    if (client.sendFixed === true) {
      return;
    }

    const oldSend = client.send;
    client.send = (message: any) => {
      oldSend.call(client, message);
      if (this.exchange !== "currencycom") {
        //console.log(this.exchange, message);
      }
    };

    client.sendFixed = true;
  }

  //async useToken() {
  //  return await firebase.getIdToken().then((idToken) => {
  //this.ccxt.headers["x-auth-token"] = idToken;
  //  });
  //}

  useApiKey() {
    this.ccxt.apiKey = "test";
    this.ccxt.secret = "test";
  }

  //@ts-ignore
  async loadMarkets(...args) {
    //await this.useToken();
    return await this.ccxt.loadMarkets(...args);
  }

  //@ts-ignore
  async watchOrderBook(...args) {
    //await this.useToken();
    // @ts-ignore
    return await this.ccxt.watchOrderBook(...args);
  }

  //@ts-ignore
  async watchTicker(...args) {
    //await this.useToken();
    // @ts-ignore
    return await this.ccxt.watchTicker(...args);
  }

  //@ts-ignore
  async closeWs(...args) {
    return await this.ccxt.close(...args);
  }

  //@ts-ignore
  async fetchTickers(...args) {
    //await this.useToken();
    return await this.ccxt.fetchTickers(...args);
  }

  //@ts-ignore
  async fetchTicker(...args) {
    //await this.useToken();
    //@ts-ignore
    return await this.ccxt.fetchTicker(...args);
  }

  //@ts-ignore
  async fetchOrders(...args) {
    //await this.useToken();
    //this.useApiKey();
    return await this.ccxt.fetchOrders(...args);
  }

  //@ts-ignore
  async fetchOpenOrders(...args) {
    //await this.useToken();
    //this.useApiKey();
    return await this.ccxt.fetchOpenOrders(...args);
  }

  //@ts-ignore
  async fetchClosedOrders(...args) {
    //await this.useToken();
    //this.useApiKey();
    return await this.ccxt.fetchClosedOrders(...args);
  }

  //@ts-ignore
  async fetchMyTrades(...args) {
    //await this.useToken();
    //this.useApiKey();
    return await this.ccxt.fetchMyTrades(...args);
  }

  //@ts-ignore
  async fetchBalance(...args) {
    //await this.useToken();
    //this.useApiKey();
    return await this.ccxt.fetchBalance(...args);
  }

  //@ts-ignore
  async createMarketBuyOrder(...args) {
    //await this.useToken();
    //this.useApiKey();
    //@ts-ignore
    return await this.ccxt.createMarketBuyOrder(...args);
  }

  //@ts-ignore
  async createLimitBuyOrder(...args) {
    //await this.useToken();
    //this.useApiKey();
    //@ts-ignore
    return await this.ccxt.createLimitBuyOrder(...args);
  }

  //@ts-ignore
  async createLimitSellOrder(...args) {
    //await this.useToken();
    //this.useApiKey();
    //@ts-ignore
    return await this.ccxt.createLimitSellOrder(...args);
  }
}
