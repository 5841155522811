import { poloniex, Currency } from "ccxt";
import { Dictionary } from "ccxt";

export class PoloniexEx {
  static fixPrototype() {
    poloniex.prototype.safeNetwork = function (networkId: string) {
      const networksById: Dictionary<string> = {
        AVAX: "AVAXC",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    const fetchCurrenciesOld = poloniex.prototype.fetchCurrencies;
    poloniex.prototype.fetchCurrencies = async function (params = {}) {
      const result: Dictionary<Currency> = await fetchCurrenciesOld.call(this, params);
      for (const currency of Object.values(result)) {
        //Networks
        if (currency.networks === undefined) {
          const networkId = currency.info.blockchain;
          const network = this.safeNetwork(networkId);
          const fee = this.safeNumber(currency.info, "txFee");

          currency.networks = {};
          currency.networks[network] = {
            id: networkId,
            network: network,
            fee: fee,
            info: currency.info,
          };
        }

        //Fees
        if (currency.fees === undefined) {
          currency.fees = {};
          for (const network of Object.values(currency.networks)) {
            currency.fees[network.network] = network.fee;
          }
        }
      }
      return result;
    };
  }
}
