// noinspection JSPotentiallyInvalidConstructorUsage

import { gate } from "ccxt";
import { TransactionFeeData, Market, Dictionary, MarketSide, Balances, Account, MaxBorrowable } from "ccxt";
import { ErrorUtils } from "@/Ccxt/ErrorUtils";

const gateio = gate;

export class GateioEx {
  static fixPrototype() {
    gateio.prototype.loadTransactionFees = async function () {
      if (this.transactionFees === undefined) {
        this.transactionFees = this.loadTransactionFees();
      }
    };

    gateio.prototype.safeNetwork = function (networkId: string) {
      const networksById: Dictionary<string> = {
        AVAX_C: "AVAXC",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    const fetchSpotMarketsOld = gateio.prototype.fetchSpotMarkets;
    gateio.prototype.fetchSpotMarkets = async function (params = {}) {
      const markets: Market[] = await fetchSpotMarketsOld.call(this, params);
      for (const market of markets) {
        //NOTE: CrossMargin needs at least VIP 1, we are VIP 0.
        //      Once we are VIP 1 we need to uncomment the code below.
        //      All margined market has both cross and isolated margin.
        market.crossMargin = false; //market.info.leverage !== undefined;
        market.crossMaxLeverage = market.crossMargin ? 3 : undefined; //All cross margin is 3x

        market.isolatedMargin = market.info.leverage !== undefined;
        market.isolatedMaxLeverage = market.info.leverage;
      }
      return markets;
    };

    gateio.prototype.fetchCurrencies = async function (params = {}) {
      /**
       * @method
       * @name gate#fetchCurrencies
       * @description fetches all available currencies on an exchange
       * @param {} params extra parameters specific to the gate api endpoint
       * @returns {} an associative dictionary of currencies
       */
      // sandbox/testnet only supports future markets
      const apiBackup = this.safeValue(this.urls, "apiBackup");
      if (apiBackup !== undefined) {
        return {};
      }
      const response = await this.publicSpotGetCurrencies(params);
      //
      //    {
      //        "currency": "BCN",
      //        "delisted": false,
      //        "withdraw_disabled": true,
      //        "withdraw_delayed": false,
      //        "deposit_disabled": true,
      //        "trade_disabled": false
      //    }
      //

      //To have currencyOnly for grouping
      for (let i = 0; i < response.length; i++) {
        const entry = response[i];
        const currency = this.safeString(entry, "currency") as string;
        entry.isOld = currency.endsWith("_OLD");
        if (currency.includes("_")) {
          const parts = currency.split("_");
          entry.currencyPart1 = parts[0];
          entry.currencyPart2 = parts[1];
        } else {
          entry.currencyPart1 = currency;
          entry.currencyPart2 = undefined;
        }
      }

      const result = {};
      const dataByCurrencyId = this.groupBy(response, "currencyPart1");
      const currencyIds = Object.keys(dataByCurrencyId);
      for (let i = 0; i < currencyIds.length; i++) {
        const currencyId = currencyIds[i];
        const currencyIdLower = currencyId.toLowerCase();
        const code = this.safeCurrencyCode(currencyId);
        const chains = dataByCurrencyId[currencyId];
        const networks = {};
        let currencyActive = undefined;
        let depositEnabled = undefined;
        let withdrawEnabled = undefined;
        for (let j = 0; j < chains.length; j++) {
          const chain = chains[j];
          const isOld = this.safeValue(chain, "isOld", false);
          if (isOld) {
            continue;
          }
          const currencyPart1 = this.safeString(chain, "currencyPart1");
          if (currencyPart1 === "USD") {
            //NOTE We do not handle USD currency's networks.
            //     There are different USD stable coins that are using the same chain,
            //     and we can not handle the same network multiple times.
            continue;
          }
          const networkId = this.safeString(chain, "chain");
          const network = this.safeNetwork(networkId);
          const currencyPart2 = this.safeString(chain, "currencyPart2");
          //@ts-ignore
          if (networks[network] !== undefined) {
            //@ts-ignore
            const currencyPart2Existing = this.safeString(networks[network].info, "currencyPart2");
            if (currencyPart2Existing === undefined) {
              continue;
            }
            if (currencyPart2 !== undefined) {
              console.log(
                `Gateio: "${network}" network will be overwritten for symbol "${code}". Old value: ${currencyPart2Existing},` +
                  `new value: ${currencyPart2}`,
                //@ts-ignore
                networks[network].info,
                chain
              );
            }
          }
          const delisted = this.safeValue(chain, "delisted");
          const withdrawDisabled = this.safeValue(chain, "withdraw_disabled", false);
          const depositDisabled = this.safeValue(chain, "deposit_disabled", false);
          const tradeDisabled = this.safeValue(chain, "trade_disabled", false);
          const listed = !delisted;
          const canWithdraw = !withdrawDisabled;
          const canDeposit = !depositDisabled;
          const tradeEnabled = !tradeDisabled;
          const active = listed && tradeEnabled && canWithdraw && canDeposit;
          //NOTE: If a currency is disabled, only the "currencyPart2 === undefined" chain contains this information.
          //      Other chains may be enabled (at least in the api), but still the currency disabled on all chains!
          //      Gate.io website withdrawal page works like that.
          if (currencyPart2 === undefined) {
            currencyActive = currencyActive === undefined || active ? active : currencyActive;
            depositEnabled = depositEnabled === undefined || canDeposit ? canDeposit : depositEnabled;
            withdrawEnabled = withdrawEnabled === undefined || canWithdraw ? canWithdraw : withdrawEnabled;
          }
          //@ts-ignore
          networks[network] = {
            id: networkId,
            network: network,
            active: active,
            deposit: canDeposit,
            withdraw: canWithdraw,
            fee: undefined,
            precision: this.parseNumber("1e-6"),
            limits: {
              withdraw: {
                min: undefined,
                max: undefined,
              },
            },
            info: chain,
          };
        }
        //@ts-ignore
        result[code] = {
          id: currencyId,
          lowerCaseId: currencyIdLower,
          name: undefined,
          code: code,
          precision: this.parseNumber("1e-6"),
          info: undefined,
          active: currencyActive,
          deposit: depositEnabled,
          withdraw: withdrawEnabled,
          fee: undefined,
          fees: [],
          limits: this.limits,
          networks: networks,
        };
      }
      return result;
    };

    gateio.prototype.parseWithdrawFeePercent = function (fee: string) {
      if (!fee.endsWith("%")) {
        return;
      }

      return this.parseNumber(fee.slice(0, -1)) / 100;
    };

    const fetchTransactionFeesOld = gateio.prototype.fetchTransactionFees;
    gateio.prototype.fetchTransactionFees = async function (this: gate, codes = undefined, params = {}) {
      //@ts-ignore
      const transactionFeeData: TransactionFeeData = await fetchTransactionFeesOld.call(this, codes, params);
      const response = transactionFeeData.info;

      for (const withdraw of Object.values(transactionFeeData.withdraw)) {
        for (const [networkId, fee] of Object.entries(withdraw)) {
          const network = this.safeNetwork(networkId);
          if (network !== networkId) {
            delete withdraw[networkId];
            withdraw[network] = fee;
          }
        }
      }

      const withdrawPercents: Dictionary<number> = {};
      for (let i = 0; i < response.length; i++) {
        const entry = response[i];
        const currencyId = this.safeString(entry, "currency");
        const code = this.safeCurrencyCode(currencyId);
        const withdrawPercent = this.safeString(entry, "withdraw_percent");
        withdrawPercents[code] = this.parseWithdrawFeePercent(withdrawPercent);

        const currency = this.currencies[code];
        if (currency !== undefined) {
          currency.name = this.safeString(entry, "name");
        }
      }
      transactionFeeData.withdrawPercent = withdrawPercents;
      return transactionFeeData;
    };

    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    gateio.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString2(ticker, "currency_pair", "contract");
      const symbol = this.safeSymbol(marketId, market);
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "highest_bid"),
        bidVolume: undefined,
        ask: this.safeNumber(ticker, "lowest_ask"),
        askVolume: undefined,
        last: this.safeNumber(ticker, "last"),
      };
    };

    gateio.prototype.fetchMaxBorrowableCross = async function (market: Market, side: MarketSide) {
      const quoteLimit = this.parseNumber(market.info.max_quote_amount);
      const limit = side === "quote" ? quoteLimit : undefined;

      return await this.privateMarginGetCrossBorrowable({
        currency: side === "base" ? market.base : market.quote,
      }).then((data: any) => {
        const result: MaxBorrowable = { amount: this.safeNumber(data, "amount"), limit: limit, quoteLimit: quoteLimit };
        return result;
      });
    };

    gateio.prototype.fetchMaxBorrowableIsolated = async function (market: Market, side: MarketSide) {
      const quoteLimit = this.parseNumber(market.info.max_quote_amount);
      const limit = side === "quote" ? quoteLimit : undefined;

      return await this.privateMarginGetBorrowable({
        currency: side === "base" ? market.base : market.quote,
        currency_pair: market.id,
      })
        .then((data: any) => {
          const result: MaxBorrowable = {
            amount: this.safeNumber(data, "amount"),
            limit: limit,
            quoteLimit: quoteLimit,
          };
          return result;
        })
        .catch((reason: any) => {
          let amount: number | undefined = undefined;
          const errorObj = ErrorUtils.parseError(reason);
          if (errorObj?.label === "MARGIN_BALANCE_NOT_ENOUGH") {
            amount = 0;
          }
          const result: MaxBorrowable = { amount: amount, limit: limit, quoteLimit: quoteLimit };
          return result;
        });
    };

    const fetchBalanceOld = gateio.prototype.fetchBalance;
    gateio.prototype.fetchBalance = async function (params = {}) {
      try {
        return await fetchBalanceOld.call(this, params);
      } catch (e: any) {
        const errorObj = ErrorUtils.parseError(e);
        if (errorObj?.label === "CROSS_ACCOUNT_NOT_FOUND") {
          // @ts-ignore
          const balances: Balances = { info: undefined };
          return balances;
        }
        throw e;
      }
    };

    gateio.prototype.transferEx = async function (
      code: string,
      amount: number,
      fromAccount: Account,
      toAccount: Account,
      fromSymbol?: string,
      toSymbol?: string,
      params: { symbol?: string } = {}
    ) {
      if (fromSymbol !== undefined && toSymbol !== undefined) {
        throw new Error("Gateio wrong fromSymbol/toSymbol in transferEx: " + fromSymbol + ", " + toSymbol);
      }
      if (fromSymbol !== undefined) {
        params.symbol = this.market(fromSymbol).id;
      }
      if (toSymbol !== undefined) {
        params.symbol = this.market(toSymbol).id;
      }
      return await gateio.prototype.transfer.call(this, code, amount, fromAccount, toAccount, params);
    };

    gateio.prototype.getCrossMarginRisk = function (balance: any) {
      return this.safeFloat(balance?.info, "risk");
    };

    gateio.prototype.getIsolatedMarginRisk = function (balance: any, marketId: string) {
      const assets = balance?.info;
      const data = assets?.find((asset: any) => asset.currency_pair === marketId);
      //If we can not find the marketId, but we have info, this means the marginRisk is 0
      if (data === undefined && balance?.info !== undefined) {
        return 0;
      }
      let marginLevel = this.safeFloat(data, "risk");
      if (marginLevel === 9999.99) {
        marginLevel = 0;
      }
      return marginLevel;
    };
  }
}
