// noinspection JSPotentiallyInvalidConstructorUsage

import { bittrex } from "ccxt";

export class BittrexEx {
  static fixPrototype() {
    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    bittrex.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString(ticker, "symbol");
      market = this.safeMarket(marketId, market, "-");
      const symbol = market["symbol"];
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "bidRate"),
        bidVolume: undefined,
        ask: this.safeNumber(ticker, "askRate"),
        askVolume: undefined,
        last: this.safeNumber(ticker, "lastTradeRate"),
      };
    };
  }
}
