import {
  Exchange,
  Market,
  MarketSide,
  MaxBorrowableBag,
  MaxBorrowable,
  Dictionary,
  WithdrawFeeByNetworks,
  OrderSide,
} from "ccxt";
import { TRUNCATE, ROUND } from "ccxt/js/base/functions/number";

declare module "ccxt" {
  export interface Exchange {
    urlsEx: {
      spotMarket?: string;
      deposit?: string;
      withdraw?: string;
    };
    crossMarginData: Dictionary<CrossMarginData>;
    isolatedMarginData: Dictionary<Dictionary<IsolatedMarginData>>;
    transactionFeeData?: TransactionFeeData;
    transferRoutes?: TransferRoutes;
    accountInfos?: AccountInfos;
    tieredIsolatedLeverages?: TieredLeverages;

    getWithdrawFeeByNetworks: (currencyCode: string) => WithdrawFeeByNetworks | undefined;
    getWithdrawFeePercent: (currencyCode: string) => number | undefined;

    getCrossMarginRisk: (balances: Balances | undefined, quote?: string) => number | undefined;
    getIsolatedMarginRisk: (balances: IsolatedBalances | undefined, marketId: string, quote?: string
      ) => number | undefined; //prettier-ignore

    loadAllMarginData: (reload?: boolean) => Promise<void>;
    loadAllCrossMarginData: (reload?: boolean) => Promise<void>;
    loadAllIsolatedMarginData: (reload?: boolean) => Promise<void>;
    loadTransactionFeeData: (reload?: boolean) => Promise<void>;
    fetchAllCrossMarginData: () => Promise<CrossMarginData[]>;
    fetchAllIsolatedMarginData: () => Promise<IsolatedMarginData[]>;
    fetchTransactionFees: () => Promise<TransactionFeeData>;
    fetchTransactionFee: (currencyCode: string) => Promise<TransactionFeeData>;
    extendCurrency: (currencyCode: string, reload?: boolean, callback?: () => void) => Promise<void>;
    fetchMaxBorrowable: (market: Market, side: MarketSide) => Promise<MaxBorrowableBag>;
    fetchMaxBorrowableCross: (market: Market, side: MarketSide) => Promise<MaxBorrowable>;
    fetchMaxBorrowableIsolated: (market: Market, side: MarketSide) => Promise<MaxBorrowable>;
    transfer: (code: string, amount: number, fromAccount: Account, toAccount: Account,
      params?: Record<string, unknown>) => Promise<void>; //prettier-ignore
    transferEx: (code: string, amount: number, fromAccount: Account, toAccount: Account,
      fromSymbol?: string, toSymbol?: string, params?: Record<string, unknown>) => Promise<void>; //prettier-ignore
    accountIsEnabled: (account: Account) => boolean;
  }
}

const getValue = (result: PromiseSettledResult<MaxBorrowable>) => {
  return result && result.status === "fulfilled" ? result.value : undefined;
};

export class ExchangeEx {
  static extend(exchange: Exchange) {
    exchange.requestPromiseMap = new Map<string, Promise<void>>();
    exchange.crossMarginData = {};
    exchange.isolatedMarginData = {};
    exchange.transactionFeeData = undefined;
  }

  static extendPrototype() {
    Exchange.prototype.loadAllMarginData = async function (this: Exchange, reload = false) {
      const promise1 = this.loadAllCrossMarginData(reload);
      const promise2 = this.loadAllIsolatedMarginData(reload);
      await Promise.all([promise1, promise2]);
    };
    Exchange.prototype.loadAllCrossMarginData = async function (this: Exchange, reload = false) {
      // noinspection PointlessBooleanExpressionJS
      if (!this.has.fetchAllCrossMarginData === true) {
        return;
      }
      return this.requestPromise("fetchAllCrossMarginData", reload, async () => {
        this.crossMarginData = {};
        const marginDataList = await this.fetchAllCrossMarginData();
        marginDataList.forEach((marginData) => {
          this.crossMarginData[marginData.currencyCode] = marginData;
        });
      });
    };

    Exchange.prototype.loadAllIsolatedMarginData = async function (this: Exchange, reload = false) {
      // noinspection PointlessBooleanExpressionJS
      if (!this.has.fetchAllIsolatedMarginData === true) {
        return;
      }
      return this.requestPromise("fetchAllIsolatedMarginData", reload, async () => {
        this.isolatedMarginData = {};
        const marginDataList = await this.fetchAllIsolatedMarginData();
        marginDataList.forEach((marginData) => {
          let markets = this.isolatedMarginData[marginData.currencyCode];
          if (markets === undefined) {
            markets = {};
            this.isolatedMarginData[marginData.currencyCode] = markets;
          }
          markets[marginData.symbol] = marginData;
        });
      });
    };

    Exchange.prototype.loadTransactionFeeData = async function (this: Exchange, reload = false) {
      // noinspection PointlessBooleanExpressionJS
      if (!this.has.fetchTransactionFees === true) {
        return;
      }
      return this.requestPromise("fetchTransactionFees", reload, async () => {
        const transactionFeeData = await this.fetchTransactionFees();
        for (const [currencyCode, withdrawFeeByNetworks] of Object.entries(transactionFeeData.withdraw)) {
          const currency = this.currencies?.[currencyCode];
          if (currency === undefined) {
            //console.log(this.id, "loadTransactionFeeData, Currency not found: " + currencyCode);
            continue;
          }

          //@ts-ignore
          currency.fees = withdrawFeeByNetworks;
          for (const [networkName, withdrawFee] of Object.entries(withdrawFeeByNetworks)) {
            const network = currency.networks?.[networkName];
            if (network === undefined) {
              //console.log(this.id, "loadTransactionFeeData, Network not found: " + networkName);
              continue;
            }
            network.fee = withdrawFee;
          }
        }
        this.transactionFeeData = transactionFeeData;
      });
    };

    Exchange.prototype.requestPromise = async function (key: string, reload: boolean, doRequest: () => Promise<void>) {
      const promise: Promise<void> = this.requestPromiseMap.get(key);
      if (promise === undefined || reload) {
        const promise = doRequest();
        this.requestPromiseMap.set(key, promise);
        await promise.catch((error) => {
          this.requestPromiseMap.delete(key);
          throw error;
        });
      }
      return promise;
    };

    Exchange.prototype.getWithdrawFeeByNetworks = function (this: Exchange, currencyCode: string) {
      let result: WithdrawFeeByNetworks | undefined = this.currencies[currencyCode]?.fees;
      if (result === undefined || Object.values(result).length === 0) {
        result = this.transactionFeeData?.withdraw?.[currencyCode];
      }
      return result;
    };

    Exchange.prototype.getWithdrawFeePercent = function (currencyCode: string) {
      return this.transactionFeeData?.withdrawPercent?.[currencyCode];
    };

    Exchange.prototype.fetchMaxBorrowable = async function (this: Exchange, market: Market, side: MarketSide) {
      if (market.crossMargin && this.fetchMaxBorrowableCross === undefined) {
        throw new Error("fetchMaxBorrowableCross is not defined for: " + this.id);
      }
      if (market.isolatedMargin && this.fetchMaxBorrowableIsolated === undefined) {
        throw new Error("fetchMaxBorrowableIsolated is not defined for: " + this.id);
      }

      const promises: Promise<MaxBorrowable>[] = [
        market.crossMargin ? this.fetchMaxBorrowableCross(market, side) : Promise.reject(),
        market.isolatedMargin ? this.fetchMaxBorrowableIsolated(market, side) : Promise.reject(),
      ];
      const [crossResult, isolatedResult] = await Promise.allSettled(promises);

      const maxBorrowableBag: MaxBorrowableBag = {
        cross: getValue(crossResult),
        isolated: getValue(isolatedResult),
      };
      return maxBorrowableBag;
    };

    //
    // PRECISION START
    //
    Exchange.prototype.amountToPrecisionEx = function (symbol: string, amount: number, round = false) {
      const market = this.market(symbol);
      const precision = market.precision["amount"];
      return this.decimalToPrecisionEx(amount, precision, round);
    };

    Exchange.prototype.priceToPrecisionEx = function (symbol: string, price: number, round = false) {
      const market = this.market(symbol);
      const precision = market.precision["price"];
      return this.decimalToPrecisionEx(price, precision, round);
    };

    Exchange.prototype.costToPrecisionEx = function (symbol: string, cost: number, round = false) {
      return this.quoteToPrecisionEx(symbol, cost, round);
    };

    Exchange.prototype.feeToPrecisionEx = function (
      orderSide: OrderSide,
      symbol: string,
      feeCost: number,
      round = false
    ) {
      //TODO Handle BNB fee for binance
      if (orderSide === "buy") {
        return this.baseToPrecisionEx(symbol, feeCost, round);
      } else if (orderSide === "sell") {
        return this.quoteToPrecisionEx(symbol, feeCost, round);
      } else {
        throw new Error("Wrong orderSide: " + orderSide);
      }
    };

    Exchange.prototype.baseToPrecisionEx = function (symbol: string, baseValue: number, round = false) {
      const market = this.market(symbol);
      return this.currencyToPrecisionEx(market.base, baseValue, round);
    };

    Exchange.prototype.quoteToPrecisionEx = function (symbol: string, quoteValue: number, round = false) {
      const market = this.market(symbol);
      return this.currencyToPrecisionEx(market.quote, quoteValue, round);
    };

    Exchange.prototype.currencyToPrecisionEx = function (
      code: string,
      value: number,
      round = false,
      networkCode: string
    ) {
      //TODO Use networkCode param to network specific precision
      const precision = this.currencies?.[code]?.precision;
      return this.decimalToPrecisionEx(value, precision, round);
    };

    Exchange.prototype.decimalToPrecisionEx = function (value: number, precision?: number, round = false) {
      if (precision === undefined) {
        return value;
      }
      const roundingMode = round ? ROUND : TRUNCATE;
      return parseFloat(this.decimalToPrecision(value, roundingMode, precision, this.precisionMode, this.paddingMode));
    };
    //
    // PRECISION END
    //
  }
}
