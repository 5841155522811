// noinspection JSPotentiallyInvalidConstructorUsage

import { okcoin } from "ccxt";

export class OkcoinEx {
  static fixPrototype() {
    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    okcoin.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString(ticker, "instrument_id");
      market = this.safeMarket(marketId, market, "-");
      const symbol = market["symbol"];
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "best_bid"),
        bidVolume: this.safeNumber(ticker, "best_bid_size"),
        ask: this.safeNumber(ticker, "best_ask"),
        askVolume: this.safeNumber(ticker, "best_ask_size"),
        last: this.safeNumber(ticker, "last"),
      };
    };
  }
}
