import { bitfinex2, Dictionary } from "ccxt";

export class Bitfinex2Ex {
  static fix(bitfinex2: bitfinex2) {
    bitfinex2.getCurrencyId = function (code: string) {
      return code;
    };

    bitfinex2.safeNetwork = function (networkId: string) {
      const networksById = {
        LUNA: "LUNC",
        LUNA2: "LUNA",
      };
      return this.safeString(networksById, networkId, networkId);
    };

    bitfinex2.fetchCurrencies = async function (params = {}) {
      /**
       * @method
       * @name bitfinex2#fetchCurrencies
       * @description fetches all available currencies on an exchange
       * @param {} params extra parameters specific to the bitfinex2 api endpoint
       * @returns {} an associative dictionary of currencies
       */
      const labels = [
        "pub:list:currency",
        "pub:map:currency:sym", // maps symbols to their API symbols, BAB > BCH
        "pub:map:currency:label", // verbose friendly names, BNT > Bancor
        "pub:map:currency:unit", // maps symbols to unit of measure where applicable
        "pub:map:currency:undl", // maps derivatives symbols to their underlying currency
        "pub:map:currency:pool", // maps symbols to underlying network/protocol they operate on
        "pub:map:currency:explorer", // maps symbols to their recognised block explorer URLs
        "pub:map:currency:tx:fee", // maps currencies to their withdrawal fees https://github.com/ccxt/ccxt/issues/7745,
        "pub:map:tx:method", // maps withdrawal/deposit methods to their API symbols
      ];
      const config = labels.join(",");
      const request = {
        config: config,
      };
      const response = await this.publicGetConfConfig(this.extend(request, params));
      //
      //     [
      //
      //         a list of symbols
      //         ["AAA","ABS","ADA"],
      //
      //         // sym
      //         // maps symbols to their API symbols, BAB > BCH
      //         [
      //             [ 'BAB', 'BCH' ],
      //             [ 'CNHT', 'CNHt' ],
      //             [ 'DSH', 'DASH' ],
      //             [ 'IOT', 'IOTA' ],
      //             [ 'LES', 'LEO-EOS' ],
      //             [ 'LET', 'LEO-ERC20' ],
      //             [ 'STJ', 'STORJ' ],
      //             [ 'TSD', 'TUSD' ],
      //             [ 'UDC', 'USDC' ],
      //             [ 'USK', 'USDK' ],
      //             [ 'UST', 'USDt' ],
      //             [ 'USTF0', 'USDt0' ],
      //             [ 'XCH', 'XCHF' ],
      //             [ 'YYW', 'YOYOW' ],
      //             // ...
      //         ],
      //         // label
      //         // verbose friendly names, BNT > Bancor
      //         [
      //             [ 'BAB', 'Bitcoin Cash' ],
      //             [ 'BCH', 'Bitcoin Cash' ],
      //             [ 'LEO', 'Unus Sed LEO' ],
      //             [ 'LES', 'Unus Sed LEO (EOS)' ],
      //             [ 'LET', 'Unus Sed LEO (ERC20)' ],
      //             // ...
      //         ],
      //         // unit
      //         // maps symbols to unit of measure where applicable
      //         [
      //             [ 'IOT', 'Mi|MegaIOTA' ],
      //         ],
      //         // undl
      //         // maps derivatives symbols to their underlying currency
      //         [
      //             [ 'USTF0', 'UST' ],
      //             [ 'BTCF0', 'BTC' ],
      //             [ 'ETHF0', 'ETH' ],
      //         ],
      //         // pool
      //         // maps symbols to underlying network/protocol they operate on
      //         [
      //             [ 'SAN', 'ETH' ], [ 'OMG', 'ETH' ], [ 'AVT', 'ETH' ], [ 'EDO', 'ETH' ],
      //             [ 'ESS', 'ETH' ], [ 'ATD', 'EOS' ], [ 'ADD', 'EOS' ], [ 'MTO', 'EOS' ],
      //             [ 'PNK', 'ETH' ], [ 'BAB', 'BCH' ], [ 'WLO', 'XLM' ], [ 'VLD', 'ETH' ],
      //             [ 'BTT', 'TRX' ], [ 'IMP', 'ETH' ], [ 'SCR', 'ETH' ], [ 'GNO', 'ETH' ],
      //             // ...
      //         ],
      //         // explorer
      //         // maps symbols to their recognised block explorer URLs
      //         [
      //             [
      //                 'AIO',
      //                 [
      //                     "https://mainnet.aion.network",
      //                     "https://mainnet.aion.network/#/account/VAL",
      //                     "https://mainnet.aion.network/#/transaction/VAL"
      //                 ]
      //             ],
      //             // ...
      //         ],
      //         // fee
      //         // maps currencies to their withdrawal fees
      //         [
      //             ["AAA",[0,0]],
      //             ["ABS",[0,131.3]],
      //             ["ADA",[0,0.3]],
      //         ],
      //     ]
      //

      //Added By DAVID start
      const sym = this.safeValue(response, 1, []);
      sym.forEach((item: any) => {
        this.commonCurrencies[item[0]] = item[1].toUpperCase();
      });
      //Added By DAVID stop

      const indexed = {
        sym: this.indexBy(this.safeValue(response, 1, []), 0),
        label: this.indexBy(this.safeValue(response, 2, []), 0),
        unit: this.indexBy(this.safeValue(response, 3, []), 0),
        undl: this.indexBy(this.safeValue(response, 4, []), 0),
        pool: this.indexBy(this.safeValue(response, 5, []), 0),
        explorer: this.indexBy(this.safeValue(response, 6, []), 0),
        fees: this.indexBy(this.safeValue(response, 7, []), 0),
      };
      const ids = this.safeValue(response, 0, []);
      const result: Dictionary<any> = {};
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        if (id.indexOf("F0") >= 0) {
          // we get a lot of F0 currencies, skip those
          continue;
        }
        const code = this.safeCurrencyCode(id);
        const label = this.safeValue(indexed["label"], id, []);
        const name = this.safeString(label, 1);
        const pool = this.safeValue(indexed["pool"], id, []);
        const type = this.safeString(pool, 1);
        const feeValues = this.safeValue(indexed["fees"], id, []);
        const fees = this.safeValue(feeValues, 1, []);
        const fee = this.safeNumber(fees, 1);
        const undl = this.safeValue(indexed["undl"], id, []);
        const precision = 8; // default precision, todo: fix "magic constants"
        const fid = "f" + id;
        result[code] = {
          id: fid,
          uppercaseId: id,
          code: code,
          info: [id, label, pool, feeValues, undl],
          type: type,
          name: name,
          active: true,
          deposit: undefined,
          withdraw: undefined,
          fee: fee,
          precision: precision,
          limits: {
            amount: {
              min: 1 / Math.pow(10, precision),
              max: undefined,
            },
            withdraw: {
              min: fee,
              max: undefined,
            },
          },
        };
        const networks = {};
        const currencyNetworks = this.safeValue(response, 8, []);
        const cleanId = id.replace("F0", "");
        for (let j = 0; j < currencyNetworks.length; j++) {
          const pair = currencyNetworks[j];
          const networkId = this.safeString(pair, 0);
          const currencyId = this.safeString(this.safeValue(pair, 1, []), 0);
          if (currencyId === cleanId) {
            const network = this.safeNetwork(networkId);
            networks[network] = {
              info: networkId,
              id: networkId.toLowerCase(),
              network: networkId,
              active: undefined,
              deposit: undefined,
              withdraw: undefined,
              fee: undefined,
              precision: undefined,
              limits: {
                withdraw: {
                  min: undefined,
                  max: undefined,
                },
              },
            };
          }
        }
        const keysNetworks = Object.keys(networks);
        const networksLength = keysNetworks.length;
        if (networksLength > 0) {
          result[code]["networks"] = networks;
        }
      }
      return result;
    };
  }
}
