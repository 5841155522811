// noinspection JSPotentiallyInvalidConstructorUsage

import { kucoin, Currency, Dictionary, Network } from "ccxt";

export class KucoinEx {
  static fixPrototype() {
    kucoin.prototype.safeNetwork = function (networkId: string) {
      networkId = networkId.toUpperCase();
      const networksById: Dictionary<string> = {
        TERRA: "LUNA",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    kucoin.prototype.extendCurrency = async function (currencyCode: string, reload = false, callback?: () => void) {
      const currency: Currency = this.currencies[currencyCode];
      if (currency.extended === true && reload === false) {
        return;
      }

      return this.requestPromise("extendCurrency_" + currencyCode, reload, async () => {
        //console.log("Requesting currency from " + this.id, currencyCode);
        const response = await this.publicGetCurrenciesCurrency({ currency: currencyCode });

        const networks: Dictionary<Network> = {};
        const fees: Dictionary<number> = {};
        for (const chain of response.data.chains) {
          // Use chain instead of chainName, because for "FRA" coin they are different and looks like chain is better,
          //   of course after calling toUpperCase() on it.
          // Later if you want to change it back, please check "FRA" coin/network.
          const networkId = chain.chain;
          const network = this.safeNetwork(networkId);
          const fee = chain.withdrawalMinFee;
          networks[network] = {
            id: networkId,
            network: network,
            deposit: chain.isDepositEnabled,
            withdraw: chain.isWithdrawEnabled,
            fee: fee,
            info: chain,
          };
          fees[network] = fee;
        }

        currency.networks = networks;
        currency.fees = fees;
        currency.extended = true;

        if (callback) {
          callback();
        }
      });
    };

    kucoin.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString(ticker, "symbol");
      const symbol = this.safeSymbol(marketId, market);
      return {
        symbol: symbol,
        bid: this.parseNumber(this.safeString2(ticker, "buy", "bestBid")),
        bidVolume: this.safeNumber(ticker, "bestBidSize"),
        ask: this.parseNumber(this.safeString2(ticker, "sell", "bestAsk")),
        askVolume: this.safeNumber(ticker, "bestAskSize"),
        last: this.safeNumber(ticker, "price"),
      };
    };
  }
}
