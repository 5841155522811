// noinspection JSPotentiallyInvalidConstructorUsage

import { cryptocom, Dictionary } from "ccxt";
//@ts-ignore
import { TICK_SIZE, DECIMAL_PLACES } from "ccxt/js/base/functions/number";

export class CryptocomEx {
  static fixPrototype() {
    const oldDescribe = cryptocom.prototype.describe;
    cryptocom.prototype.describe = function () {
      const describe = oldDescribe.call(this);
      describe.api.spot.private.post["private/get-currency-networks"] = 10 / 3;
      describe.has.fetchCurrencies = true;
      return describe;
    };

    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    cryptocom.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString(ticker, "i");
      market = this.safeMarket(marketId, market, "_");
      const symbol = market["symbol"];
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "b"),
        bidVolume: undefined,
        ask: this.safeNumber(ticker, "k"),
        askVolume: undefined,
        last: this.safeNumber(ticker, "a"),
      };
    };

    cryptocom.prototype.setCurrencyPrecisionsFromMarket = function () {
      //NOTE: Copied from Exchange.js, but modified for using only for precision.
      //      Both base and quote currency use amountPrecision, I'm not sure that this is ok.
      const allCurrencies = [];
      const markets = this.toArray(this.markets);
      for (let i = 0; i < markets.length; i++) {
        const market = markets[i];
        const defaultCurrencyPrecision = this.precisionMode === DECIMAL_PLACES ? 8 : this.parseNumber("0.00000001");
        const marketPrecision = this.safeValue(market, "precision", {});
        if ("base" in market) {
          const currencyPrecision = this.safeValue2(marketPrecision, "base", "amount", defaultCurrencyPrecision);
          const currency = {
            code: this.safeString(market, "base"),
            precision: currencyPrecision,
          };
          allCurrencies.push(currency);
        }
        if ("quote" in market) {
          const currencyPrecision = this.safeValue2(marketPrecision, "quote", "amount", defaultCurrencyPrecision);
          const currency = {
            code: this.safeString(market, "quote"),
            precision: currencyPrecision,
          };
          allCurrencies.push(currency);
        }
      }
      const groupedCurrencies = this.groupBy(allCurrencies, "code");
      const codes = Object.keys(groupedCurrencies);
      for (let i = 0; i < codes.length; i++) {
        const code = codes[i];
        const groupedCurrenciesCode = this.safeValue(groupedCurrencies, code, []);
        let highestPrecisionCurrency = this.safeValue(groupedCurrenciesCode, 0);
        for (let j = 1; j < groupedCurrenciesCode.length; j++) {
          const currentCurrency = groupedCurrenciesCode[j];
          if (this.precisionMode === TICK_SIZE) {
            highestPrecisionCurrency =
              currentCurrency["precision"] < highestPrecisionCurrency["precision"]
                ? currentCurrency
                : highestPrecisionCurrency;
          } else {
            highestPrecisionCurrency =
              currentCurrency["precision"] > highestPrecisionCurrency["precision"]
                ? currentCurrency
                : highestPrecisionCurrency;
          }
        }
        if (code in this.currencies) {
          this.currencies[code].precision = highestPrecisionCurrency.precision;
        }
      }
    };

    const setMarketsOld = cryptocom.prototype.setMarkets;
    cryptocom.prototype.setMarkets = function (markets, currencies = undefined) {
      const result = setMarketsOld.call(this, markets, currencies);
      this.setCurrencyPrecisionsFromMarket();
      return result;
    };

    cryptocom.prototype.fetchCurrencies = async function (params = {}) {
      const response = await this.spotPrivatePostPrivateGetCurrencyNetworks(params);
      const currencyMap = this.safeValue(this.safeValue(response, "result", {}), "currency_map", {});
      const currencyIds = Object.keys(currencyMap);
      const result = {};
      for (let i = 0; i < currencyIds.length; i++) {
        const currencyId = currencyIds[i];
        const currency = this.safeCurrency(currencyId);
        const code = currency["code"];
        const info = currencyMap[currencyId];
        const chains = this.safeValue(info, "network_list", []);
        const networks = {};
        let currencyActive = undefined;
        let depositEnabled = undefined;
        let withdrawEnabled = undefined;
        const fees: Dictionary<number> = {};
        for (let j = 0; j < chains.length; j++) {
          const chain = chains[j];
          const canDeposit = this.safeValue(chain, "deposit_enabled");
          const canWithdraw = this.safeValue(chain, "withdraw_enabled");
          // noinspection RedundantConditionalExpressionJS
          const active = canDeposit && canWithdraw ? true : false;
          currencyActive = currencyActive === undefined || active ? active : currencyActive;
          depositEnabled = depositEnabled === undefined || canDeposit ? canDeposit : depositEnabled;
          withdrawEnabled = withdrawEnabled === undefined || canWithdraw ? canWithdraw : withdrawEnabled;
          const networkId = this.safeString(chain, "network_id");
          const network = this.safeNetwork(networkId);
          const withdrawFee = this.safeNumber(chain, "withdrawal_fee");
          fees[network] = withdrawFee;

          //@ts-ignore
          networks[network] = {
            id: networkId,
            network: network,
            active: active,
            deposit: canDeposit,
            withdraw: canWithdraw,
            fee: withdrawFee,
            precision: undefined,
            limits: {
              withdraw: {
                min: this.safeNumber(chain, "min_withdrawal_amount"),
                max: undefined,
              },
            },
            info: chain,
          };
        }
        //@ts-ignore
        result[code] = {
          id: currencyId,
          code: code,
          name: this.safeString(info, "full_name"),
          active: currencyActive,
          deposit: depositEnabled,
          withdraw: withdrawEnabled,
          networks: networks,
          info: info,
          fee: undefined,
          fees: fees,
          precision: undefined,
          limits: {
            amount: {
              min: undefined,
              max: undefined,
            },
          },
        };
      }
      return result;
    };
  }
}
