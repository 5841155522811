// noinspection JSPotentiallyInvalidConstructorUsage

import { Dictionary, ftx } from "ccxt";

export class FtxEx {
  static fixPrototype() {
    ftx.prototype.safeNetwork = function (networkId: string) {
      if (networkId !== undefined) {
        networkId = networkId.toUpperCase();
      }
      const networksById: Dictionary<string> = {
        ERC20: "ETH",
        BEP2: "BNB",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    ftx.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString(ticker, "name");
      if (marketId in this.markets_by_id) {
        market = this.markets_by_id[marketId];
      }
      const symbol = this.safeSymbol(marketId, market);
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "bid"),
        bidVolume: this.safeNumber(ticker, "bidSize"),
        ask: this.safeNumber(ticker, "ask"),
        askVolume: this.safeNumber(ticker, "askSize"),
        last: this.safeNumber(ticker, "last"),
      };
    };

    ftx.prototype.fetchCurrencies = async function (params = {}) {
      /**
       * @method
       * @name ftx#fetchCurrencies
       * @description fetches all available currencies on an exchange
       * @param {} params extra parameters specific to the ftx api endpoint
       * @returns {} an associative dictionary of currencies
       */
      const response = await this.publicGetWalletCoins(params);
      const currencies = this.safeValue(response, "result", []);

      const result: Dictionary<any> = {};
      for (let i = 0; i < currencies.length; i++) {
        const currency = currencies[i];
        const id = this.safeString(currency, "id");
        const code = this.safeCurrencyCode(id);
        const name = this.safeString(currency, "name");
        const canWithdraw = this.safeValue(currency, "canWithdraw");
        const canDeposit = this.safeValue(currency, "canDeposit");
        const active = canWithdraw && canDeposit;
        const networks = {};
        const methods = this.safeValue(currency, "methods", []);
        for (let j = 0; j < methods.length; j++) {
          const networkId = methods[j];
          const network = this.safeNetwork(networkId);
          networks[network] = {
            id: networkId,
            network: network,
            active: undefined,
            deposit: undefined,
            withdraw: undefined,
            fee: undefined,
            precision: undefined,
            limits: {
              deposit: {
                min: undefined,
                max: undefined,
              },
              withdraw: {
                min: undefined,
                max: undefined,
              },
            },
            info: networkId,
          };
        }
        result[code] = {
          id: id,
          code: code,
          info: currency,
          type: undefined,
          name: name,
          active: active,
          deposit: canDeposit,
          withdraw: canWithdraw,
          networks: networks,
          fee: undefined,
          precision: undefined,
          limits: {
            withdraw: { min: undefined, max: undefined },
            amount: { min: undefined, max: undefined },
          },
        };
      }
      return result;
    };
  }
}
