let newrelic: any;
//@ts-ignore
import("newrelic").then((result) => (newrelic = result)).catch(() => ({}));

import ccxt, { Exchange, Market, Currency, Network } from "ccxt";
import { Bitfinex2Ex } from "@/Ccxt/Extend/Bitfinex2Ex";
import { CryptocomEx } from "@/Ccxt/Extend/CryptocomEx";
import { GateioEx } from "@/Ccxt/Extend/GateioEx";
import { AaxEx } from "@/Ccxt/Extend/AaxEx";
import { PhemexEx } from "@/Ccxt/Extend/PhemexEx";
import { BinanceEx } from "@/Ccxt/Extend/BinanceEx";
import { ExchangeEx } from "@/Ccxt/Extend/ExchangeEx";
import { OkxEx } from "@/Ccxt/Extend/OkxEx";
import { Hitbtc3Ex } from "@/Ccxt/Extend/Hitbtc3Ex";
import { PoloniexEx } from "@/Ccxt/Extend/PoloniexEx";
import { BitmartEx } from "@/Ccxt/Extend/BitmartEx";
import { KucoinEx } from "@/Ccxt/Extend/KucoinEx";
import { FtxEx } from "@/Ccxt/Extend/FtxEx";
import { BittrexEx } from "@/Ccxt/Extend/BittrexEx";
import { CoinbaseproEx } from "@/Ccxt/Extend/CoinbaseproEx";
import { OkcoinEx } from "@/Ccxt/Extend/OkcoinEx";
import { Dictionary } from "ccxt";

export interface RequestInfo {
  url: string;
  date: number;
}

ExchangeEx.extendPrototype();
AaxEx.fixPrototype();
BinanceEx.fixPrototype();
BitmartEx.fixPrototype();
BittrexEx.fixPrototype();
CoinbaseproEx.fixPrototype();
CryptocomEx.fixPrototype();
FtxEx.fixPrototype();
GateioEx.fixPrototype();
Hitbtc3Ex.fixPrototype();
KucoinEx.fixPrototype();
OkxEx.fixPrototype();
OkcoinEx.fixPrototype();
PhemexEx.fixPrototype();
PoloniexEx.fixPrototype();

export default class CcxtPrivate {
  ccxt: Exchange;
  requestInfos: RequestInfo[] = [];

  close() {
    this.ccxt.close();
  }

  clone() {
    const clone = new CcxtPrivate(this.exchange, this.enableRateLimit);
    this.copyCachedDataTo(clone);
    return clone;
  }

  private copyCachedDataTo(clone: CcxtPrivate) {
    // setMarkets
    //clone.ccxt.setMarkets(Object.values(this.ccxt.markets), this.ccxt.currencies);
    clone.ccxt.markets = this.ccxt.markets;
    clone.ccxt.markets_by_id = this.ccxt.markets_by_id;
    clone.ccxt.symbols = this.ccxt.symbols;
    clone.ccxt.ids = this.ccxt.ids;
    clone.ccxt.currencies = this.ccxt.currencies;
    clone.ccxt.currencies_by_id = this.ccxt.currencies_by_id;
    clone.ccxt.codes = this.ccxt.codes;

    //set custom cached data
    clone.ccxt.requestPromiseMap = this.ccxt.requestPromiseMap;
    clone.ccxt.crossMarginData = this.ccxt.crossMarginData;
    clone.ccxt.isolatedMarginData = this.ccxt.isolatedMarginData;
    clone.ccxt.transactionFeeData = this.ccxt.transactionFeeData;
  }

  constructor(private exchange: string, private enableRateLimit = false) {
    // @ts-ignore
    this.ccxt = new ccxt.pro[exchange]({
      enableRateLimit: enableRateLimit,
      //proxy: "/cors-proxy/",
      tokenBucket: {
        maxCapacity: 2000, //gateio has more than 1000 markets to watch
      },
      fetchImplementation: (_url: string, options: any) => {
        const { startBackgroundTransaction, url } = this.getNewrelic(_url);
        return startBackgroundTransaction((url?.hostname ?? "") + (url?.pathname ?? ""), "Fetch", () => {
          const start = Date.now();
          //@ts-ignore
          return fetch(_url, options).catch((error) => {
            const time = Date.now() - start;
            //console.log("REASON", typeof error, Object.keys(error), error.cause, error);
            if (error.cause) {
              error.message =
                error.message + ", caused by: " + error.cause.message + ", " + time + " ms, " + Date.now();
            }
            throw error;
          }); //.then((response) => {
          //NOTE: For now we comment this out until find a better solution
          //this.requestInfos.push({
          //  date: Date.now(),
          //  url: _url,
          //});
          //return response;
          //});
        });
      },
    });
    ExchangeEx.extend(this.ccxt);
    //this.ccxt.timeout = 10000;
    this.ccxt.urlsEx = {};
    this.ccxt.options.checksum = false;
    this.ccxt.commonCurrencies = {}; //instead of "substituteCommonCurrencyCodes: false" in the options

    if (exchange === "aax") {
      this.ccxt.urlsEx.deposit = "https://account.aax.com/en-US/wallets/spot/deposit?coin=%baseId%";
      this.ccxt.urlsEx.withdraw = "https://account.aax.com/en-US/wallets/spot/withdraw?coin=%baseId%";
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://trade.aax.com/en-US/spot/" + market.baseId + ":" + market.quoteId;
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks).map((_network) => {
            const network: Network = {
              network: _network.network,
              deposit: _network.deposit,
              withdraw: _network.withdraw,
              fee: _network.fee,
            };
            return network;
          });
        },
      };
    }
    if (exchange === "zb") {
      // @ts-ignore
      this.ccxt.urls.api.ws = "wss://api.zb.live/websocket";
      this.ccxt.arbi = {
        getCurrencyNetworks: (currency: Currency) => {
          return currency.info.map((info: any) => {
            const network: Network = {
              network: info.chainName,
              deposit: info.canDeposit,
              withdraw: info.canWithdraw,
              fee: info.fee,
            };
            return network;
          });
        },
      };
    }
    if (exchange === "binance") {
      this.ccxt.options.accountsByType.cross = "MARGIN";
      this.ccxt.options.accountsByType.isolated = "ISOLATEDMARGIN";
      this.ccxt.transferRoutes = { "spot<->isolated": "cross" };
      this.ccxt.accountInfos = {
        spot: { marginMode: null },
        cross: { marginMode: "cross", fetchBalanceParams: { marginMode: "cross" } },
        isolated: { marginMode: "isolated", fetchBalanceParams: { marginMode: "isolated" } },
        restIsolated: { marginMode: "isolated", isRestIsolated: true, useBalanceOf: "isolated" },
      };
      //Copied from https://www.binance.com/en/support/faq/7fbadf3c75914efc90e8b831709b644f
      this.ccxt.tieredIsolatedLeverages = {
        3: [3, 2.14285714286, 1.8, 1.615384615385, 1.5],
        5: [5, 4.2, 3.67, 3.29, 3],
        10: [10, 8.9, 8.04, 7.35, 6.79, 6.31, 5.91, 5.56, 5.26, 5],
      };

      this.ccxt.options.ws.cost = 6;
      //NOTE: FetchCurrencies needs authentication
      this.ccxt.apiKey = "XHvyEn2uPNerxMTqUbYYGdw6KMkI8sMagSwrHcrEFXFYO3RfpiWz8ytguO5TR1yg";
      this.ccxt.secret = "ZfxFiJET9yNSAR7FvKVoGdZjyJRzCwvgqcydByE9aTl6ZZA9oAoeJGn8UCa06BK5";
      this.ccxt.urlsEx.deposit = "https://www.binance.com/en/my/wallet/account/main/deposit/crypto/%baseId%";
      this.ccxt.urlsEx.withdraw = "https://www.binance.com/en/my/wallet/account/main/withdrawal/crypto/%baseId%";
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://www.binance.com/en/trade/" + market.id + "?theme=dark&type=spot";
        },
        getCurrencyNetworks: (currency: Currency) => {
          //@ts-ignore
          const networkArray: any[] = currency.networks;
          return networkArray.map((_network) => {
            const network: Network = {
              name: _network.name,
              network: _network.network,
              deposit: _network.depositEnable,
              withdraw: _network.withdrawEnable,
              fee: _network.withdrawFee,
            };
            return network;
          });
        },
      };
    }
    if (exchange === "bitmart") {
      //NOTE: fetchTransactionFee needs authentication, and also withdrawal permission!
      //      It works by providing only the apiKey, so the secret is not needed.
      this.ccxt.apiKey = "432d3913f582f3e86ceb680641f54edd4b12ebb0";
      this.ccxt.secret = "-";
      this.ccxt.uid = "27030224";
      this.ccxt.urlsEx.withdraw = "https://www.bitmart.com/balance/en";
      this.ccxt.urlsEx.deposit = "https://www.bitmart.com/balance/en";

      this.ccxt.arbi = {
        transactionFeeLazy: true,
        //NOTE: Does not work because of rate limit for fetchTransactionFee is "5 request / 5 sec"
        //updateCurrencyLazy: (currency: Currency, reload = false) => {
        //  this.ccxt.extendCurrency(currency.code, reload);
        //},
        getMarketUrl(market: Market) {
          return "https://www.bitmart.com/trade/en?symbol=" + market.id + "&layout=basic";
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks);
        },
        feeCanBeQueried: true,
      };
    }
    if (exchange === "okx") {
      //NOTE: FetchCurrencies needs authentication
      this.ccxt.apiKey = "d9f65351-8798-4dbd-ac51-8ca0bfbd3d58";
      this.ccxt.secret = "A06CCED4D12A65DF7B9477D74F5B5248";
      this.ccxt.password = ",R@pa4thp+DsVAb";
      this.ccxt.urlsEx.withdraw = "https://www.okx.com/balance/withdrawal";
      this.ccxt.urlsEx.deposit = "https://www.okx.com/balance/recharge";
      this.ccxt.options.fetchMarkets = ["spot"];

      this.ccxt.accountInfos = {
        funding: { marginMode: null, fetchBalanceParams: { type: "funding" } },
        //NOTE: We have "Single currency cross margin" account, so we use crossExcludesRestBalanceInQuote: true
        trading: { marginMode: "cross", crossExcludesRestBalanceInQuote: true },
      };

      //We set mainNet to false, so ccxt.fetchCurrencies will not convert it wrongly.
      const oldPrivateGetAssetCurrencies = this.ccxt.privateGetAssetCurrencies;
      this.ccxt.privateGetAssetCurrencies = async function (params = {}) {
        const response = await oldPrivateGetAssetCurrencies.call(this, params);
        for (const currencyInfo of response.data) {
          currencyInfo.mainNet = false;
        }
        return response;
      };

      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://www.okx.com/trade-spot/" + market.id;
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks).map((_network) => {
            const network: Network = {
              network: _network.network, //safeNetwork is in place
              deposit: _network.deposit,
              withdraw: _network.withdraw,
              fee: _network.fee,
            };
            return network;
          });
        },
      };
    }
    if (exchange === "gateio") {
      this.ccxt.transferRoutes = { "cross<->isolated": "spot", "isolated<->isolated": "spot" };
      this.ccxt.accountInfos = {
        spot: { marginMode: null },
        cross: { marginMode: "cross", disabled: true, fetchBalanceParams: { marginMode: "cross" } },
        isolated: { marginMode: "isolated", fetchBalanceParams: { marginMode: "isolated" } },
        restIsolated: { marginMode: "isolated", isRestIsolated: true, useBalanceOf: "isolated" },
      };

      this.ccxt.options.ws = { cost: 2 };
      this.ccxt.rateLimit = 50;
      this.ccxt.apiKey = "a3d5f0f150f5bb3bb943e8017d75bd13";
      this.ccxt.secret = "4422851aa4bcf7c98f637794ca50da56bcb993685a180734d993c753e777e32f";
      this.ccxt.urlsEx.withdraw = "https://www.gate.io/myaccount/withdraw/%baseId%";
      this.ccxt.urlsEx.deposit = "https://www.gate.io/myaccount/deposit/%baseId%";

      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://www.gate.io/trade/" + market.id;
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks).map((_network) => {
            const network: Network = {
              network: _network.network,
              deposit: _network.deposit,
              withdraw: _network.withdraw,
              fee: _network.fee,
            };
            return network;
          });
        },
      };
    }
    if (exchange === "cryptocom") {
      this.ccxt.options.ws = { cost: 20 };
      this.ccxt.apiKey = "UebG9CuhbysAkeXmepZuG2";
      this.ccxt.secret = "NR9FDMCLiFbJx2gU8QW8us";

      // Margin trading is not available in the EU
      // https://help.crypto.com/en/articles/4475382-margin-trading-geo-restrictions
      //TODO This is not tested/used
      this.ccxt.accountInfos = {
        spot: { marginMode: null },
      };

      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://crypto.com/exchange/trade/spot/" + market.id;
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks).map((_network) => {
            const network: Network = {
              network: _network.network,
              deposit: _network.deposit,
              withdraw: _network.withdraw,
              fee: _network.fee,
            };
            return network;
          });
        },
      };
    }
    if (exchange === "kucoin") {
      this.ccxt.options.ws = { cost: 3 };
      //NOTE: Order book depth > 100 needs authentication
      this.ccxt.apiKey = "62fe5b757a5bf700011278c2";
      this.ccxt.secret = "4ecb2483-02fb-4d24-8700-6855c2c04b81";
      this.ccxt.password = "YmsN5Ib4d4fQQh8nb4pNm8gT545a";
      this.ccxt.rateLimit = 150;
      this.ccxt.urlsEx.withdraw = "https://www.kucoin.com/assets/withdraw/%baseId%";
      this.ccxt.urlsEx.deposit = "https://www.kucoin.com/assets/coin/%baseId%";
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://www.kucoin.com/trade/" + market.id;
        },
        updateCurrencyLazy: (currency: Currency, reload = false) => {
          this.ccxt.extendCurrency(currency.code, reload, () => {
            currency.myNetworks = Object.values(currency.networks);
          });
        },
      };
    }
    if (exchange === "hitbtc3") {
      this.ccxt.urlsEx.withdraw = "https://hitbtc.com/account";
      this.ccxt.urlsEx.deposit = "https://hitbtc.com/account";
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://hitbtc.com/" + market.id;
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks);
        },
      };
    }
    if (exchange === "poloniex") {
      this.ccxt.urlsEx.withdraw = "https://poloniex.com/wallet/profile-security";
      this.ccxt.urlsEx.deposit = "https://poloniex.com/wallet/profile-security";
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://poloniex.com/exchange/" + market.id;
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks);
        },
      };
    }
    if (exchange === "coinbasepro") {
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://pro.coinbase.com/trade/" + market.id;
        },
        getCurrencyNetworks: (currency: Currency) => {
          const network: Network = { network: this.getCoinbaseNetwork(currency.info.default_network) };
          return network.network !== undefined ? [network] : [];
        },
      };
    }
    if (exchange === "bitcoincom") {
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://fmfw.io/" + market.id;
        },
      };
      //NOTE: No network info found :(
    }
    if (exchange === "ndax") {
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://ndax.io/prices/" + market.id;
        },
      };
      //NOTE: No network info found :(
    }
    if (exchange === "ftx") {
      //NOTE: wallet/coins returns networks also, so we use it
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://ftx.com/trade/" + market.id;
        },
        //TODO Find for network: active, withdraw, deposit, fee, etc...
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks);
        },
      };
    }
    if (exchange === "zipmex") {
      this.ccxt.options.ws = {
        cost: 0.2,
      };
      this.ccxt.urlsEx.withdraw = "https://trade.zipmex.com/global/wallets/overview";
      this.ccxt.urlsEx.deposit = "https://trade.zipmex.com/global/wallets/overview";
      this.ccxt.arbi = {
        //NOTE: It does not use the symbol in the url, it uses the last symbol opened
        //getMarketUrl(market: Market) {
        //  return "https://trade.zipmex.com/global/trade/" + market.info.Symbol;
        //},
      };
      //NOTE: No network info found :(
    }
    if (exchange === "currencycom") {
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://currency.com/" + market.baseId.toLowerCase() + "-to-" + market.quoteId.toLowerCase();
        },
      };
      //NOTE: No network info found :(
    }
    if (exchange === "bittrex") {
      this.ccxt.options.ws = {
        cost: 0.2,
      };
      this.ccxt.urlsEx.withdraw = "https://global.bittrex.com/balance";
      this.ccxt.urlsEx.deposit = "https://global.bittrex.com/balance";
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://global.bittrex.com/Market/Index?MarketName=" + market.quoteId + "-" + market.baseId;
        },
        //NOTE: For now only ETH network is extracted.
        //      Based on "info.coinType" and/or "info.baseAddress" more networks can be extracted.
        getCurrencyNetworks: (currency: Currency) => {
          const baseAddress = currency.info.baseAddress;
          const addressMap: Dictionary<string> = {
            "0xfbb1b73c4f0bda4f67dca266ce6ef42f520fbb98": "ETH", //This is bittrex's address on the ETH chain.
            a4XCDQ7AnRH9opZ4h6LcG3g7ocSV2SbBmS: "FIRO", //This is bittrex's address on the FIRO chain.
          };
          const network = addressMap[baseAddress];
          if (network !== undefined) {
            const _network: Network = {
              network: network,
            };
            return [_network];
          }
          return [];
        },
      };
    }
    if (exchange === "phemex") {
      this.ccxt.urlsEx.withdraw = "https://phemex.com/assets/withdrawal";
      this.ccxt.urlsEx.deposit = "https://phemex.com/assets/deposit";
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://phemex.com/spot/trade/" + market.baseId + market.quoteId;
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.values(currency.networks).map((_network) => {
            const network: Network = {
              network: _network.network,
              deposit: _network.active,
              withdraw: _network.active,
            };
            return network;
          });
        },
      };
    }
    if (exchange === "bitfinex2") {
      Bitfinex2Ex.fix(this.ccxt);
      this.ccxt.commonCurrencies = {
        LUNA: "LUNC",
        LUNA2: "LUNA",
      };
      this.ccxt.arbi = {
        getMarketUrl(market: Market) {
          return "https://trading.bitfinex.com/t/" + market.baseId + ":" + market.quoteId + "?type=exchange";
        },
        getCurrencyNetworks: (currency: Currency) => {
          return Object.keys(currency.networks ?? {}).map((_network) => {
            const network: Network = {
              network: _network,
            };
            return network;
          });
        },
      };
    }

    //this.applyProxyToWsUrls();
  }

  applyProxyToWsUrls(proxyHost: string) {
    const proxy = "wss://" + proxyHost + "/ws-proxy/";
    let urlsApi = this.ccxt.urls.api;
    if (this.exchange === "bitopro") {
      // @ts-ignore
      urlsApi = this.ccxt.urls;
    }

    // Fix for kukoin exchange
    // @ts-ignore
    if (urlsApi.ws === undefined) {
      return;
    }

    // @ts-ignore
    if (typeof urlsApi.ws === "string") {
      // @ts-ignore
      urlsApi.ws = proxy + urlsApi.ws;
    } else {
      // @ts-ignore
      for (const [name, url] of Object.entries(urlsApi.ws)) {
        if (typeof url === "string") {
          // @ts-ignore
          urlsApi.ws[name] = proxy + url;
        } else {
          // @ts-ignore
          for (const [name2, url2] of Object.entries(url)) {
            if (typeof url2 === "string") {
              // @ts-ignore
              urlsApi.ws[name][name2] = proxy + url2;
            } else {
              // @ts-ignore
              for (const [name3, url3] of Object.entries(url2)) {
                // @ts-ignore
                urlsApi.ws[name][name2][name3] = proxy + url3;
              }
            }
          }
        }
      }
    }
  }

  getCurrency(name: string) {
    return this.ccxt.currencies[name];
  }

  hasMarket(name: string) {
    return this.ccxt.markets[name] !== undefined;
  }

  getMarkets() {
    return this.ccxt.markets;
  }

  getMarket(name: string) {
    return this.ccxt.markets[name];
  }

  getTakerFee() {
    // @ts-ignore
    const tradingFees = this.ccxt.fees.trading;

    if (tradingFees.percentage === false) {
      console.log("Trading fees are not in percentage for exchange", this.ccxt.id);
      return;
    }

    let takerFee = tradingFees.taker;
    if (takerFee === undefined && tradingFees.tierBased === true && tradingFees.tiers !== undefined) {
      takerFee = tradingFees.tiers.taker[0][1];
    }

    if (takerFee === undefined) {
      console.log("Trading fees can not be determined for exchange", this.ccxt.id);
      return;
    }

    return takerFee;
  }

  private getCoinbaseNetwork(name: string) {
    const map: Dictionary<string> = {
      "": "", //Network of fiat currencies is empty string (on coinbasepro at least)
      algorand: "ALGO",
      avacchain: "AVAXC",
      bitcoin: "BTC",
      bitcoincash: "BCH",
      cardano: "ADA",
      celo: "CELO",
      cosmos: "ATOM",
      dash: "DASH",
      deso: "DESO",
      dfinity: "ICP",
      dogecoin: "DOGE",
      eosio: "EOS",
      ethereum: "ETH",
      ethereumclassic: "ETC",
      filecoin: "FIL",
      flow: "FLOW",
      horizen: "ZEN",
      kusama: "KSM",
      litecoin: "LTC",
      mina: "MINA",
      oasis: "ROSE",
      optimism: "OP",
      polkadot: "DOT",
      ripple: "XRP",
      solana: "SOL",
      stacks: "STX",
      stellar: "XLM",
      tezos: "XTZ",
      zcash: "ZEC",
    };
    return map[name] !== undefined && map[name] !== "" ? map[name] : name;
  }

  getNewrelic(_url?: string) {
    let url: URL | undefined = undefined;
    let startBackgroundTransaction;
    if (newrelic === undefined) {
      startBackgroundTransaction = (p1: any, p2: any, p3: any) => {
        return p3();
      };
    } else {
      startBackgroundTransaction = newrelic.startBackgroundTransaction.bind(newrelic);
      url = new URL(_url ?? "");
    }
    return { startBackgroundTransaction, url };
  }
}
