// noinspection JSPotentiallyInvalidConstructorUsage

import { coinbasepro } from "ccxt";

export class CoinbaseproEx {
  static fixPrototype() {
    const oldDescribe = coinbasepro.prototype.describe;
    coinbasepro.prototype.describe = function () {
      const describe = oldDescribe.call(this);
      //It has a very limited fetchTickers, so we disable it. See at parseTicker.
      describe.has.fetchTickers = false;
      return describe;
    };

    //NOTE: Only for lower memory consumption (Exchange.safeTicker uses lots of memory)
    coinbasepro.prototype.parseTicker = function (ticker: any, market = undefined) {
      const symbol = market === undefined ? undefined : market["symbol"];
      let bid = undefined;
      let ask = undefined;
      let last;
      if (Array.isArray(ticker)) {
        //For fetchTickers
        last = this.safeNumber(ticker, 4);
      } else {
        //For fetchTicker
        bid = this.safeNumber(ticker, "bid");
        ask = this.safeNumber(ticker, "ask");
        last = this.parseNumber(this.safeString2(ticker, "price", "last"));
      }
      return {
        symbol: symbol,
        bid: bid,
        bidVolume: undefined,
        ask: ask,
        askVolume: undefined,
        last: last,
      };
    };
  }
}
