import { phemex, Currency } from "ccxt";
import { Dictionary } from "ccxt";

export class PhemexEx {
  static fixPrototype() {
    const oldDescribe = phemex.prototype.describe;
    phemex.prototype.describe = function () {
      const describe = oldDescribe.call(this);
      describe.api.public.get.push("cfg/chain-settings");
      return describe;
    };

    phemex.prototype.safeNetwork = function (networkId: string) {
      const networksById: Dictionary<string> = {
        BNB: "BSC",
        AVAX: "AVAXC",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    const fetchCurrenciesOld = phemex.prototype.fetchCurrencies;
    phemex.prototype.fetchCurrencies = async function (params = {}) {
      //@ts-ignore
      const promise1 = fetchCurrenciesOld.call(this, params);
      const promise2 = this.publicGetCfgChainSettings({ currency: "" });
      return Promise.all([promise1, promise2]).then((value) => {
        const currencies = value[0];
        const chainSettings = value[1];
        const currencyArray: Array<Currency> = Object.values(currencies);
        for (const currency of currencyArray) {
          const code = currency.code;
          const chains = chainSettings.data[code];
          const networks = {};
          let currencyActive = undefined;
          if (chains !== undefined) {
            for (const chain of chains) {
              const networkId = this.safeString(chain, "chainName");
              const network = this.safeNetwork(networkId);
              const active = this.safeValue(chain, "inUse");
              currencyActive = currencyActive === undefined || active ? active : currencyActive;
              //@ts-ignore
              networks[network] = {
                id: networkId,
                network: network,
                active: active,
                info: chain,
              };
            }
          }
          //@ts-ignore
          currency.networks = networks;
          //@ts-ignore
          if (currency.active === undefined) {
            //@ts-ignore
            currency.active = currencyActive;
          }
        }
        //console.log(chainSettings.data);
        return currencies;
      });
    };
  }
}
