// noinspection JSPotentiallyInvalidConstructorUsage

import { aax, Dictionary, TransactionFeeData } from "ccxt";

export class AaxEx {
  static fixPrototype() {
    const oldDescribe = aax.prototype.describe;
    aax.prototype.describe = function () {
      const describe = oldDescribe.call(this);
      describe.has.fetchTransactionFees = true;
      //"common" is a not documented API, aax uses this api on theirs website and it's publicly available
      describe.urls.api.common = "https://api.{hostname}/common";
      describe.api.common = {
        get: ["currency"],
      };
      return describe;
    };

    aax.prototype.safeNetwork = function (networkId: string) {
      const networksById: Dictionary<string> = {
        ADA: "",
        ALGO: "",
        ALGORAND: "ALGO",
        ASTR: "",
        ATOM: "",
        AVAX: "",
        "AVAX C-Chain": "AVAXC",
        Arbitrum: "ARBITRUM",
        BCH: "",
        BEP20: "BSC",
        BSC: "",
        BTC: "",
        "BTC-Lighting": "",
        CELO: "",
        DASH: "",
        DOCK: "",
        DOGE: "",
        DOT: "",
        ELF: "",
        EOS: "",
        ERC20: "ETH",
        ETC: "",
        ETH: "",
        FIL: "",
        FIO: "",
        "FTM (Fantom)": "FTM",
        Fantom: "FTM",
        HBAR: "",
        ICP: "",
        KAVA: "",
        KLAY: "",
        KSM: "",
        LTC: "",
        LTNM: "",
        LUNA2: "LUNA",
        LUNC: "",
        "LUNC (Terra Classic)": "LUNC",
        MATIC: "",
        MMUI: "",
        NEAR: "",
        "NEO N3": "NEO3",
        ONE: "",
        Ontology: "ONT",
        Optimism: "OPTIMISM",
        PHAE: "",
        POLYGON: "MATIC",
        QTUM: "",
        SDN: "",
        SOL: "",
        SOLANA: "SOL",
        SPL: "",
        THETA: "",
        THORCHain: "RUNE",
        TRC20: "TRX",
        TRX: "",
        Terra: "LUNA",
        UNTB: "",
        VET: "",
        VTHO: "",
        WAVES: "",
        XLM: "",
        XRP: "",
        XTZ: "",
        ZEC: "",
        ZEN: "",
        ZIL: "",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    aax.prototype.fetchCurrencies = async function (params = {}) {
      /**
       * @method
       * @name aax#fetchCurrencies
       * @description fetches all available currencies on an exchange
       * @param {} params extra parameters specific to the aax api endpoint
       * @returns {} an associative dictionary of currencies
       */
      const response = await this.publicGetCurrencies(params);
      //
      //     {
      //         "code":1,
      //         "data":[
      //             {
      //                 "chain":"BTC",
      //                 "displayName":"Bitcoin",
      //                 "withdrawFee":"0.0004",
      //                 "withdrawMin":"0.001",
      //                 "otcFee":"0",
      //                 "enableOTC":true,
      //                 "visible":true,
      //                 "enableTransfer":true,
      //                 "transferMin":"0.00001",
      //                 "depositMin":"0.0005",
      //                 "enableWithdraw":true,
      //                 "enableDeposit":true,
      //                 "addrWithMemo":false,
      //                 "withdrawPrecision":"0.00000001",
      //                 "currency":"BTC",
      //                 "network":"BTC", // ETH, ERC20, TRX, TRC20, OMNI, LTC, XRP, XLM, ...
      //                 "minConfirm":"2"
      //             },
      //         ],
      //         "message":"success",
      //         "ts":1624330530697
      //     }
      //
      const result = {};
      const data = this.safeValue(response, "data", []);
      const dataByCurrencyId = this.groupBy(data, "currency");
      const currencyIds = Object.keys(dataByCurrencyId);
      for (let i = 0; i < currencyIds.length; i++) {
        const currencyId = currencyIds[i];
        const code = this.safeCurrencyCode(currencyId);
        const chains = dataByCurrencyId[currencyId];
        const networks = {};
        let currencyActive = undefined;
        let depositEnabled = undefined;
        let withdrawEnabled = undefined;
        for (let j = 0; j < chains.length; j++) {
          const chain = chains[j];
          const enableWithdraw = this.safeValue(chain, "enableWithdraw");
          const enableDeposit = this.safeValue(chain, "enableDeposit");
          const fee = this.safeNumber(chain, "withdrawFee");
          const visible = this.safeValue(chain, "visible");
          const active = enableWithdraw && enableDeposit && visible;
          const canDeposit = enableDeposit && visible;
          const canWithdraw = enableWithdraw && visible;
          currencyActive = currencyActive === undefined || active ? active : currencyActive;
          depositEnabled = depositEnabled === undefined || canDeposit ? canDeposit : depositEnabled;
          withdrawEnabled = withdrawEnabled === undefined || canWithdraw ? canWithdraw : withdrawEnabled;
          const networkId = this.safeString(chain, "network");
          const network = this.safeNetwork(networkId);
          //@ts-ignore
          networks[network] = {
            id: networkId,
            network: network,
            active: active,
            deposit: canDeposit,
            withdraw: canWithdraw,
            fee: fee,
            precision: this.safeNumber(chain, "withdrawPrecision"),
            limits: {
              deposit: {
                min: this.safeNumber(chain, "depositMin"),
                max: undefined,
              },
              withdraw: {
                min: this.safeNumber(chain, "withdrawMin"),
                max: undefined,
              },
            },
            info: chain,
          };
        }

        const firstChain = this.safeValue(chains, 0);
        //@ts-ignore
        result[code] = {
          id: currencyId,
          name: this.safeString(firstChain, "displayName"),
          code: code,
          precision: undefined,
          info: undefined,
          active: currencyActive,
          deposit: depositEnabled,
          withdraw: withdrawEnabled,
          fee: undefined,
          networks: networks,
          limits: {
            deposit: {
              min: undefined,
              max: undefined,
            },
            withdraw: {
              min: undefined,
              max: undefined,
            },
          },
        };
      }
      return result;
    };

    aax.prototype.parseTransactionFeePercent = function (currencyEx: any) {
      const depositHint =
        currencyEx.depositHints && currencyEx.depositHints["en-US"].indexOf("%") !== -1
          ? currencyEx.depositHints["en-US"]
          : undefined;
      if (depositHint === undefined) {
        return 0;
      }
      const matches = depositHint.matchAll(/charge (\d*[.,]?\d+)% service fee/g);
      const results = [...matches];
      if (results.length !== 1) {
        console.error("TransactionFeePercent not found in aax depositHint:", depositHint);
        return;
      }
      return this.parseNumber(results[0][1].replace(",", ".")) / 100;
    };

    aax.prototype.fetchTransactionFees = async function () {
      await this.loadMarkets();
      //This is a not documented API, aax uses this api on theirs website and it's publicly available
      const response = await this.commonGetCurrency();
      const currenciesEx = response.data.currencies;

      const withdrawFees: Dictionary<Dictionary<number>> = {};
      const withdrawPercent: Dictionary<number | undefined> = {};
      for (const currencyEx of currenciesEx) {
        const currencyId = this.safeString(currencyEx, "cur");
        const code = this.safeCurrencyCode(currencyId);
        const networkId = this.safeString(currencyEx, "protocolName");
        const network = this.safeNetwork(networkId);
        const fee = this.safeNumber(currencyEx, "withdrawFee");

        if (withdrawFees[code] === undefined) {
          withdrawFees[code] = {};
        }
        withdrawFees[code][network] = fee;

        const feePercent = this.parseTransactionFeePercent(currencyEx);
        const previousFeePercent = withdrawPercent[code];
        if (previousFeePercent !== undefined) {
          if (feePercent === undefined || feePercent <= previousFeePercent) {
            continue;
          }
        }
        withdrawPercent[code] = feePercent;
      }
      const transactionsFees: TransactionFeeData = {
        info: currenciesEx,
        withdraw: withdrawFees,
        withdrawPercent: withdrawPercent,
        deposit: {},
      };
      return transactionsFees;
    };
  }
}
