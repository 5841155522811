// noinspection JSPotentiallyInvalidConstructorUsage

import { hitbtc3, Currency, Dictionary } from "ccxt";

export class Hitbtc3Ex {
  static fixPrototype() {
    hitbtc3.prototype.safeNetwork = function (networkId: string) {
      if (networkId !== undefined) {
        networkId = networkId.toUpperCase();
      }
      const networksById: Dictionary<string> = {
        ERC20: "ETH",
        TRC20: "TRX",
        BEP20: "BSC",
        BEP2: "BNB",
        "C-CHAIN": "AVAXC",
        VECHAIN: "VET",
        NEWDOT: "DOT",
        SOLANA: "SOL",
        LUNA: "LUNC",
        LUNANEW: "LUNA",
      };
      return networksById[networkId] !== undefined && networksById[networkId] !== ""
        ? networksById[networkId]
        : networkId;
    };

    const fetchCurrenciesOld = hitbtc3.prototype.fetchCurrencies;
    // noinspection DuplicatedCode
    hitbtc3.prototype.fetchCurrencies = async function (params = {}) {
      const result: Dictionary<Currency> = await fetchCurrenciesOld.call(this, params);
      for (const currency of Object.values(result)) {
        if (currency.fees === undefined) {
          currency.fees = {};
          for (const network of Object.values(currency.networks)) {
            currency.fees[network.network] = network.fee;
          }
        }
      }
      return result;
    };
  }
}
