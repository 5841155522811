// noinspection JSPotentiallyInvalidConstructorUsage

import { Account, binance, CrossMarginData, IsolatedMarginData, MarketSide, MaxBorrowable } from "ccxt";
import { Market } from "ccxt";
import { ErrorUtils } from "@/Ccxt/ErrorUtils";

export class BinanceEx {
  static fixPrototype() {
    const oldDescribe = binance.prototype.describe;
    binance.prototype.describe = function () {
      const describe = oldDescribe.call(this);
      describe.has.fetchAllCrossMarginData = true;
      describe.has.fetchAllIsolatedMarginData = true;
      return describe;
    };

    const fetchMarketsOld = binance.prototype.fetchMarkets;
    binance.prototype.fetchMarkets = async function (params = {}) {
      const promise2 = this.sapiGetMarginCrossMarginData();
      const promise3 = this.sapiGetMarginIsolatedMarginData();
      //NOTE: having the fetchMarket last is better, this way all 3 queries can really be started at the same time
      //@ts-ignore
      const promise1 = fetchMarketsOld.call(this, params);

      return Promise.all([promise1, promise2, promise3]).then((value: [Market[], any[], any[]]) => {
        const markets = value[0];
        const crossMarginDataList = value[1];
        const isolatedMarginDataList = value[2];
        markets.map((market) => {
          // --- CROSS MARGIN ---
          const baseCrossMarginData = crossMarginDataList.find((data) => data.coin === market.info.baseAsset);
          const quoteCrossMarginData = crossMarginDataList.find((data) => data.coin === market.info.quoteAsset);

          const crossMarginablePair = (baseCrossMarginData?.marginablePairs ?? []).find(
            (symbol: string) => symbol === market.info.symbol
          );
          market.crossMargin = crossMarginablePair !== undefined;
          market.crossMaxLeverage = market.crossMargin ? 3 : undefined; //All cross margin is 3x
          market.crossBaseBorrowLimit = market.crossMargin
            ? this.parseNumber(baseCrossMarginData.borrowLimit)
            : undefined;
          market.crossQuoteBorrowLimit = market.crossMargin
            ? this.parseNumber(quoteCrossMarginData.borrowLimit)
            : undefined;
          //crossMarginPair?.isMarginTrade === true &&
          //crossMarginPair?.isBuyAllowed === true &&
          //crossMarginPair?.isSellAllowed === true;

          // --- ISOLATED MARGIN ---
          const isolatedMarginData = isolatedMarginDataList.find((data) => data.symbol === market.info.symbol);
          market.isolatedMargin = isolatedMarginData !== undefined;
          market.isolatedMaxLeverage = market.isolatedMargin
            ? this.parseNumber(isolatedMarginData.leverage)
            : undefined;
          market.isolatedBaseBorrowLimit = market.isolatedMargin
            ? this.parseNumber(isolatedMarginData.data[0].borrowLimit)
            : undefined;
          market.isolatedQuoteBorrowLimit = market.isolatedMargin
            ? this.parseNumber(isolatedMarginData.data[1].borrowLimit)
            : undefined;
          //isolatedMarginPair?.isMarginTrade === true &&
          //isolatedMarginPair?.isBuyAllowed === true &&
          //isolatedMarginPair?.isSellAllowed === true;

          market.margin = market.crossMargin || market.isolatedMargin;
          return market;
        });
        return markets;
      });
    };

    binance.prototype.fetchAllCrossMarginData = async function () {
      const response: Array<any> = await this.sapiGetMarginCrossMarginData();

      return response.map((item) => {
        const currencyId = this.safeString(item, "coin");
        const marginData: CrossMarginData = {
          currencyCode: this.safeCurrencyCode(currencyId),
          borrowLimit: this.safeNumber(item, "borrowLimit"),
          borrowable: this.safeValue(item, "borrowable"),
          dailyInterest: this.safeNumber(item, "dailyInterest"),
        };
        return marginData;
      });
    };

    binance.prototype.fetchAllIsolatedMarginData = async function () {
      const response: Array<any> = await this.sapiGetMarginIsolatedMarginData();

      const marginDataList: Array<IsolatedMarginData> = [];
      for (const item of response) {
        const currencyDataList = this.safeValue(item, "data");
        const marketId = this.safeString(item, "symbol");
        const market = this.safeMarket(marketId);
        const leverage = this.safeNumber(item, "leverage");
        for (const currencyData of currencyDataList) {
          const currencyId = this.safeString(currencyData, "coin");
          const marginData: IsolatedMarginData = {
            currencyCode: this.safeCurrencyCode(currencyId),
            symbol: market.symbol,
            maxLeverage: leverage,
            borrowLimit: this.safeNumber(currencyData, "borrowLimit"),
            dailyInterest: this.safeNumber(currencyData, "dailyInterest"),
          };
          marginDataList.push(marginData);
        }
      }
      return marginDataList;
    };

    binance.prototype.fetchMaxBorrowableCross = async function (market: Market, side: MarketSide) {
      return await this.sapiGetMarginMaxBorrowable({
        asset: side === "base" ? market.base : market.quote,
      }).then((data: any) => {
        return { amount: this.safeNumber(data, "amount"), limit: this.safeNumber(data, "borrowLimit") };
      });
    };

    binance.prototype.fetchMaxBorrowableIsolated = async function (market: Market, side: MarketSide) {
      return await this.sapiGetMarginMaxBorrowable({
        asset: side === "base" ? market.base : market.quote,
        isolatedSymbol: market.id,
      })
        .then((data: any) => {
          const result: MaxBorrowable = {
            amount: this.safeNumber(data, "amount"),
            limit: this.safeNumber(data, "borrowLimit"),
          };
          return result;
        })
        .catch((reason: any) => {
          const limit = side === "base" ? market.isolatedBaseBorrowLimit : market.isolatedQuoteBorrowLimit;
          const errorObj = ErrorUtils.parseError(reason);
          //Message: "The system does not have enough asset now."
          if (errorObj?.code === -3045) {
            const result: MaxBorrowable = { amount: 0, limit: limit, limitedByAvailability: true };
            return result;
          }
          //Message: "Isolated margin account does not exist."
          if (errorObj?.code === -11001) {
            const result: MaxBorrowable = { amount: 0, limit: limit };
            return result;
          }
          const result: MaxBorrowable = { amount: undefined, limit: undefined };
          return result;
        });
    };

    const fetchOrdersOld = binance.prototype.fetchOrders;
    binance.prototype.fetchOrders = async function (
      symbol = undefined,
      since = undefined,
      limit = undefined,
      params = {}
    ) {
      try {
        return await fetchOrdersOld.call(this, symbol, since, limit, params);
      } catch (e: any) {
        const errorObj = ErrorUtils.parseError(e);
        //Message: "Isolated margin account does not exist."
        if (errorObj?.code === -11001) {
          return [];
        }
        throw e;
      }
    };

    const fetchMyTradesOld = binance.prototype.fetchMyTrades;
    binance.prototype.fetchMyTrades = async function (
      symbol = undefined,
      since = undefined,
      limit = undefined,
      params = {}
    ) {
      try {
        return await fetchMyTradesOld.call(this, symbol, since, limit, params);
      } catch (e: any) {
        const errorObj = ErrorUtils.parseError(e);
        //Message: "Isolated margin account does not exist."
        if (errorObj?.code === -11001) {
          return [];
        }
        throw e;
      }
    };

    //NOTE: Handle when fetchTickersMethod is "publicGetTickerPrice"
    binance.prototype.parseTicker = function (ticker: any, market = undefined) {
      const marketId = this.safeString(ticker, "symbol");
      const symbol = this.safeSymbol(marketId, market);
      return {
        symbol: symbol,
        bid: this.safeNumber(ticker, "bidPrice"),
        bidVolume: this.safeNumber(ticker, "bidQty"),
        ask: this.safeNumber(ticker, "askPrice"),
        askVolume: this.safeNumber(ticker, "askQty"),
        last: this.safeNumber(ticker, "lastPrice"),
      };
    };

    binance.prototype.transferEx = async function (
      code: string,
      amount: number,
      fromAccount: Account,
      toAccount: Account,
      fromSymbol?: string,
      toSymbol?: string,
      params = {}
    ) {
      if (fromSymbol !== undefined) {
        params.fromSymbol = this.market(fromSymbol).id;
      }
      if (toSymbol !== undefined) {
        params.toSymbol = this.market(toSymbol).id;
      }
      return await binance.prototype.transfer.call(this, code, amount, fromAccount, toAccount, params);
    };

    binance.prototype.getCrossMarginRisk = function (balance: any) {
      let marginLevel = this.safeFloat(balance?.info, "marginLevel");
      if (marginLevel === 999) {
        marginLevel = 0;
      }
      return marginLevel;
    };

    binance.prototype.getIsolatedMarginRisk = function (balance: any, marketId: string) {
      const data = balance?.info?.assets?.find((asset: any) => asset?.symbol === marketId);
      //If we can not find the marketId, but we have info, this means the marginRisk is 0
      if (data === undefined && balance?.info !== undefined) {
        return 0;
      }
      let marginLevel = this.safeFloat(data, "marginLevel");
      if (marginLevel === 999) {
        marginLevel = 0;
      }
      return marginLevel;
    };

    binance.prototype.baseToPrecisionEx = function (symbol: string, baseValue: number, round = false) {
      const market = this.market(symbol);
      //@ts-ignore
      const precision = market.precision["base"];
      return this.decimalToPrecisionEx(baseValue, precision, round);
    };

    binance.prototype.quoteToPrecisionEx = function (symbol: string, quoteValue: number, round = false) {
      const market = this.market(symbol);
      //@ts-ignore
      const precision = market.precision["quote"];
      return this.decimalToPrecisionEx(quoteValue, precision, round);
    };
  }
}
